// Chakra imports
import {
  Box,
  Flex,
  Spacer,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import { columnsDataDevelopment } from './columnsData';
// @ts-ignore
import React, { useEffect } from 'react';
import Pagination from 'components/footer/Pagination';
import Filter from 'components/menu/FilterMenu';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import ListingTable from './ListingTable';
import FormComponent from './FormComponent';
import useListing from 'hooks/useListing';
import Loader from 'components/loader/Loader';
import { useLocation } from 'react-router-dom';

export default function ListingManager() {
  const { error, isLoading, listing, listingOptions, totalListing, getListing } = useListing();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract the 'page' and 'limit' parameters
  const page = queryParams.get('page') || 1; // Default to 1 if not provided
  const limit = queryParams.get('limit') || 10; // Default to 10 if not provided

  useEffect(() => {
    getListing(page, limit);
  }, [page]);

  return (
    <>
      {isLoading ? (
        <Loader opacity={0.2}></Loader>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="34px">
            Listing
          </Text>
          <Box pt={{ base: '130px', md: '30px', xl: '30px' }}>
            <Flex px="25px" mb="20px" align="center">
              <Flex align="center">
                <SearchBar me="10px" borderRadius="30px" />
                <Filter />
              </Flex>
            </Flex>
            <ListingTable
              columnsData={columnsDataDevelopment}
              tableData={listing}
              listingOptions={listingOptions}
            />
            <Spacer mt={4} />
            <Pagination totalData={totalListing} rowsPerPage={limit} />
          </Box>
        </>
      )}
    </>
  );
}
