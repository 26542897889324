import React, { createContext, useState, useContext } from 'react';

const defaultValue = {
  isOpened: false,
  closeSidebar: () => {},
  openSidebar: () => {},
};

const SidebarContext = createContext(defaultValue);

export const SidebarStore = (children) => {
  const [isOpened, setIsOpened] = useState(false);

  const openSidebar = () => {
    setIsOpened(true);
  };

  const closeSidebar = () => {
    setIsOpened(false);
  };

  return (
    <SidebarContext.Provider value={{ isOpened, openSidebar, closeSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebarContext() {
  return useContext(SidebarContext);
}
