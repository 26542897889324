import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import DateInputForm from 'components/fields/DateInputForm';
import { Formik } from 'formik';

const FormComponent = ({ onClose }) => {
  const [platform, setPlatform] = useState('');

  const initialValues = {
    username: '',
  };

  const onSubmitApproval = async (values) => {
    console.log(values)
  };

  const handlePlatformChange = (event, setFieldValue, values) => {
    const selectedPlatform = event.target.value;
    setPlatform(selectedPlatform);
  }
  
  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik initialValues={initialValues} onSubmit={onSubmitApproval}>
          {({ values, handleChange, setFieldValue }) => (
            <form>
              <SimpleGrid columns={3} spacing={5} mb="20px">
                <GridItem>
                  <FormControl>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      name="platform"
                      placeholder="Select platform"
                      // onChange={(e) =>
                      //   handlePlatformChange(e, setFieldValue, values)
                      // }
                      value={values.platform}
                    >
                      <option value="tiktok">Tiktok</option>
                      <option value="instagram">Instagram</option>
                      <option value="twitter">Twitter</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>KOL</FormLabel>
                    <Select
                      name="kol"
                      placeholder="Select KOL"
                      // onChange={(e) =>
                      //   handlePlatformChange(e, setFieldValue, values)
                      // }
                      value={values.kol}
                    >
                      <option value="tiktok">Tiktok</option>
                      <option value="instagram">Instagram</option>
                      <option value="twitter">Twitter</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>SOW</FormLabel>
                      <Select
                        name="platform"
                        placeholder="Select platform"
                        onChange={(e) =>
                          handlePlatformChange(e, setFieldValue, values)
                        }
                        value={values.platform}
                      >
                        <option value="tiktok">Tiktok</option>
                        <option value="instagram">Instagram</option>
                        <option value="twitter">Twitter</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </SimpleGrid>
              <Text mb="10px" fontSize="md" fontWeight="800">
                Timelines
              </Text>
              <SimpleGrid columns={3} spacingX={5} spacingY={5} mb="20px">
                <GridItem>
                  <FormControl>
                    <FormLabel>Slot Number</FormLabel>
                    <Input type="email" borderColor="grey" placeholder="1" />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    {/* <FormLabel fontSize="sm" fontWeight="500">
                      Set tanggal
                    </FormLabel> */}
                    <DateInputForm
                      values={values}
                      label={'Upload Date'}
                      id={'upload_date'}
                      setFieldValue={setFieldValue}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            </form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
