import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import illustration_ from 'assets/img/auth/auth-3.jpg';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthContext } from 'contexts/AuthContext';
import Loader from 'components/loader/Loader';

function Login() {
  const { userAccess, isLoading, postLogin } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();

  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    email: Yup.string().min(3, 'Email must be at least 3 characters long'),
    password: Yup.string().min(
      4,
      'Password must be at least 6 characters long',
    ),
  });

  useEffect(() => {
    if (!isLoading && userAccess) {
      navigate('/');
    }
  }, [isLoading, userAccess, navigate]);

  const handleLogin = async (values) => {
    try {
      await postLogin(values);
      toast({
        title: 'Login success!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      toast({
        title: 'Login failed, please check account credential',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    // @ts-ignore
    <DefaultAuth illustrationBackground={illustration_} image={illustration_}>
      {isLoading ? (
        <Loader opacity={1}></Loader>
      ) : (
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Sign In
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <Flex align="center" mb="25px">
              <HSeparator />
              <Text color="gray.400" mx="14px">
                {/* or */}
              </Text>
              <HSeparator />
            </Flex>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleLogin(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <FormLabel>Email</FormLabel>
                    <Field name="email">
                      {({ field }) => (
                        <Input
                          {...field}
                          id="email"
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type="mail"
                          placeholder="abc@mail.com"
                          fontWeight="500"
                          size="lg"
                        />
                      )}
                    </Field>
                    <ErrorMessage name="email" component="div" />
                  </div>
                  <div>
                    <FormLabel mt="24px">Password</FormLabel>
                    <Field name="password">
                      {({ field }) => (
                        <InputGroup size="md">
                          <Input
                            {...field}
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Min. 8 characters"
                            mb="24px"
                            size="lg"
                            type={show ? 'text' : 'password'}
                            variant="auth"
                          />
                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: 'pointer' }}
                              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                              onClick={handleClick}
                            />
                          </InputRightElement>
                        </InputGroup>
                      )}
                    </Field>
                    <ErrorMessage name="password" component="div" />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mt="24px"
                      disabled={isSubmitting}
                    >
                      Login
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
      )}
    </DefaultAuth>
  );
}

export default Login;
