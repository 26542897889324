import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'utils/api';

export default function useListing() {
  const [shipping, setShipping] = useState([]);
  const [products, setProduct] = useState([]);
  const [totalShipping, setTotalShipping] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  async function getShipping(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/shipping/data/all?page=${page}&limit=${limit}`,
      );

      setTotalShipping(data.data.total_items);
      const data_ = data.data.rows;

      data_.map((val, key) => {
        const submitted_date = val.created_at?.split('T')[0] || '';
        const shipping_id = val.id;
        const full_name = val.kol?.kol_detail?.[0]?.full_name ?? '';
        const address = val.kol?.kol_detail?.[0]?.home_address ?? '';
        const phone_number = val.kol?.kol_detail?.[0]?.whatsapp_number ?? '';
        const products = val.shipping_detail ?? [];
        const urgent = val.is_urgent ?? '';
        const shipping_type = val.shipping_type?.name ?? '';
        const status = val.shipping_action?.[0]?.action_status;
        const tracking_number = val.shipping_receipt?.tracking_number ?? '';
        const expedition =
          val.shipping_receipt?.shipping_expeditions?.name ?? '';
        const list_expeditions = data.data.expeditions;

        const object = {
          submitted_date,
          shipping_id,
          full_name,
          address,
          phone_number,
          products,
          urgent,
          shipping_type,
          status,
          tracking_number,
          expedition,
          list_expeditions
        };
        value.push(object);
      });

      setShipping(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const getAllProducts = async () => {
    try {
      let { data } = await api.get(`/product/data/all`);
      data = data.data;
      console.log(data);
      setProduct(data);
      return data;
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  const postShipping = async (body) => {
    try {
      let { data } = await api.post(`/shipping`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  const postShippingExpedition = async (index, body) => {
    try {
      let { data } = await api.post(`/shipping/${index}`, body);
      console.log(data)
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    }
  };

  return {
    shipping,
    products,
    isLoading,
    error,
    totalShipping,
    getShipping,
    getAllProducts,
    postShipping,
    postShippingExpedition,
  };
}
