// @ts-nocheck
/* eslint-disable */
import {
  Flex,
  Spacer,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import DetailCard from 'components/card/DetailCard';
import { MdCircle, MdEdit, MdOutlineDelete } from 'react-icons/md';
import React, { useMemo } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

export default function ShippingTable(props) {
  const { columnsData, tableData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const approvedColor = useColorModeValue('green.500', 'white');
  const onHoldColor = useColorModeValue('gray.500', 'white');
  const rejectedColor = useColorModeValue('red.500', 'white');
  const iconColor = useColorModeValue('blue.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  function getFirstFiveWords(str) {
    const string = str.trim().split(/\s+/);

    if (string.length > 5) {
      const words = str.split(' ');
      const firstFiveWords = words.slice(0, 5);
      return { word: firstFiveWords.join(' '), overlay: true };
    } else {
      return { word: str, overlay: false };
    }
  }

  function handleRowEdit(row) {
    console.log('row', row.id);
  }

  function handleRowDelete(row) {
    console.log('row', row.id);
  }

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'SUBMITTED DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'SHIPPING ID') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '120px', md: '120px', lg: '120px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'FULL NAME') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ADDRESS') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'PHONE NUMBER') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'PRODUCTS') {
                      let list_component = [];
                      cell.value.map((item, key) => {
                        list_component.push(
                          `${item.product.name}: ${item.quantity} pcs`,
                        );
                      });

                      data = (
                        <DetailCard
                          title="Attached Products"
                          components={list_component}
                        />
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '110px', md: '100px', lg: '100px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'URGENT') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value ? 'Yes' : 'No'}
                        </Text>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '110px', md: '100px', lg: '100px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'SHIPPING TYPE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'STATUS') {
                      let iconColor = textColor;
                      if (cell.value === 'shipped') {
                        iconColor = approvedColor;
                      } else if (cell.value === 'ordered') {
                        iconColor = onHoldColor;
                      } else {
                        iconColor = rejectedColor;
                      }

                      data = (
                        <Flex align="center">
                          <Icon
                            as={MdCircle}
                            color={iconColor}
                            w="24px"
                            h="24px"
                            me="10px"
                          />
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="500"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'TRACKING NUMBER') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'EXPEDITION') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '110px', md: '120px', lg: '120px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'ACTION') {
                      data = (
                        <Text
                          color="purple.500"
                          onClick={() => handleRowEdit(row)}
                          cursor="pointer"
                          textDecoration="underline"
                        >
                          show
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
