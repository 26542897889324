import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../utils/api";

export default function useAuthorization() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const postLogin = async (body) => {
    try {
      const { data } = await api.post('/user/login', body);

      setUser(data.data.id);
      setUserAccess(data.data.pages);
      setUserRole(data.data.role)
      navigate('/');
      setLoading(false);
      return data;
    } catch (error) {
      console.log(error)
      throw error.response.data.error;
      // return setError(JSON.stringify(error.response.data.error));
    }
  };

  const postLogout = async () => {
    try {
      const { data } = await api.post('/user/logout');
      navigate('/login');
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setUser(null);
      setUserAccess(null);
      setLoading(false);
    }
  };

  const verifyAccess = async () => {
    try {
      const { data } = await api.get('/user/check-access');
      setUser(data.data.id);
      setUserAccess(data.data.pages);
      setUserRole(data.data.role);
      setLoading(false);
    } catch (err) {
      setUserAccess(null);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    verifyAccess();
  }, []);

  return {
    user,
    userRole,
    userAccess,
    isLoading,
    error,
    setUser,
    setUserAccess,
    verifyAccess,
    postLogin,
    postLogout,
  };
}

