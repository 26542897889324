import { Box, Flex } from '@chakra-ui/react';
import Sidebar from 'components/sidebar/Sidebar';
import React, { useContext, useEffect, useState } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import {
  MdBarChart,
  MdCalendarMonth,
  MdInsertChartOutlined,
  MdLibraryAddCheck,
  MdLightbulbOutline,
  MdLocalShipping,
  MdPerson,
} from 'react-icons/md';
import { LuDatabase } from 'react-icons/lu';
import { Navigate, Outlet } from 'react-router-dom';
import { useSidebarContext } from '../contexts/SidebarContexts';
import { AuthContext } from 'contexts/AuthContext';
import Loader from 'components/loader/Loader';

// const LINK = {
//   Flow: [
//     {
//       pathname: '/kol/',
//       icon: MdBarChart,
//       label: 'Listing',
//     },
//     {
//       pathname: '/kol/dealing',
//       icon: MdLibraryAddCheck,
//       label: 'Dealing',
//     },
//     {
//       pathname: '/kol/shipping',
//       icon: MdLocalShipping,
//       label: 'Shipping',
//     },
//     {
//       pathname: '/kol/profile',
//       icon: MdPerson,
//       label: 'Profile',
//     },
//     {
//       pathname: '/kol/timeline',
//       icon: MdCalendarMonth,
//       label: 'Timeline',
//     },
//   ],
//   Report: [
//     {
//       pathname: '/dashboard/sorak-ria',
//       icon: FaInstagram,
//       label: 'Instagram',
//     },
//     {
//       pathname: '/dashboard/sorak-ria',
//       icon: FaTiktok,
//       label: 'Tiktok',
//     },
//   ],
//   Summary: {
//     pathname: '/kol/dealing',
//     icon: MdInsertChartOutlined,
//     label: 'Database',
//   },
//   Ideation: {
//     pathname: '/kol/dealing',
//     icon: MdLightbulbOutline,
//     label: 'Database',
//   },
//   Database: {
//     pathname: '/kol/dealing',
//     icon: LuDatabase,
//     label: 'Database',
//   },
// };

let userAccess_ = [
  {
    category: 'flow',
    subcategory: null,
    page: 'listing',
    pathname: '/listing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: null,
    page: 'dealing',
    pathname: '/dealing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: 'first dealing',
    page: 'information',
    pathname: '/dealing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: 'first dealing',
    page: 'verification',
    pathname: '/dealing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: 'second dealing',
    page: 'information',
    pathname: '/dealing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: 'second dealing',
    page: 'verification',
    pathname: '/dealing',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: null,
    page: 'shipping',
    pathname: '/shipping',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: null,
    page: 'profile',
    pathname: '/profile',
    parent: false,
    sidebar: true,
  },
  {
    category: 'flow',
    subcategory: null,
    page: 'timeline',
    pathname: '/timeline',
    parent: false,
    sidebar: true,
  },
  {
    category: 'report',
    subcategory: null,
    page: 'instagram',
    pathname: '/instagram',
    parent: false,
    sidebar: true,
  },
  {
    category: 'report',
    subcategory: null,
    page: 'tiktok',
    pathname: '/tiktok',
    parent: false,
    sidebar: true,
  },
  {
    category: 'summary',
    subcategory: null,
    page: 'summary',
    pathname: '/summary',
    parent: true,
    sidebar: true,
  },
  {
    category: 'ideation',
    subcategory: null,
    page: 'ideation',
    pathname: '/ideation',
    parent: true,
    sidebar: true,
  },
  {
    category: 'database',
    subcategory: null,
    page: 'database',
    pathname: '/database',
    parent: true,
    sidebar: true,
  },
];

// let pages_ = {
//   flow: [
//     {
//       category: 'flow',
//       page: 'listing',
//       pathname: '/listing',
//       parent: false,
//     },
//     {
//       category: 'flow',
//       page: 'Dealing',
//       pathname: '/dealing',
//       parent: false,
//     },
//     {
//       category: 'flow',
//       page: 'Shipping',
//       pathname: '/shipping',
//       parent: false,
//     },
//     {
//       category: 'Flow',
//       page: 'Profile',
//       pathname: '/profile',
//       parent: false,
//     },
//     {
//       category: 'Flow',
//       page: 'Timeline',
//       pathname: '/timeline',
//       parent: false,
//     },
//   ],
//   report: [
//     {
//       category: 'Report',
//       page: 'Instagram',
//       pathname: '/instagram',
//       parent: false,
//     },
//     {
//       category: 'Tiktok',
//       page: 'Tiktok',
//       pathname: '/tiktok',
//       parent: false,
//     },
//   ],
//   summary: {
//     category: 'Summary',
//     page: 'Summary',
//     pathname: '/summary',
//     parent: true,
//   },
//   ideation: {
//     category: 'Ideation',
//     page: 'Ideation',
//     pathname: '/ideation',
//     parent: true,
//   },
//   database: {
//     category: 'Database',
//     page: 'Database',
//     pathname: '/database',
//     parent: true,
//   },
// };

const SidebarRoute = () => {
  const { isOpened, openSidebar, closeSidebar } = useSidebarContext();
  const { isLoading, userAccess } = useContext(AuthContext);
  const [pages, setPages] = useState(null);

  useEffect(() => {
    if (!isLoading && userAccess) {
      mappingSidebar(userAccess);
    }
  }, [isLoading, userAccess]);

  // useEffect(() => {
  //   mappingSidebar();
  // }, []);

  // const mappingSidebar = () => {
  //   const pages_ = userAccess_.reduce((acc, page) => {
  //     if(page.sidebar) {
  //       if (page.parent) {
  //         acc[page.page] = page;
  //       } else {
  //         if (!acc[page.category]) {
  //           acc[page.category] = [];
  //         }
  //         acc[page.category].push(page);
  //       }
  //     }
  //     return acc;
  //   }, {});

  //   setPages(pages_);
  // };

  // useEffect(() => {
  //   mapUserAccess(userAccess_);
  // }, []);

  const mappingSidebar = (access) => {
    const result = {};

    access.forEach((item) => {
      const { category, entity_subcategory, ...rest } = item;

      if (item.sidebar) {
        if (item.parent) {
          // Parent categories are added directly
          result[category] = item;
        } else if (entity_subcategory) {
          // Items with subcategories
          if (!result[category]) {
            result[category] = [];
          }

          const existingSubcategory = result[category].find(
            (sub) => sub.entity_subcategory === entity_subcategory,
          );

          if (existingSubcategory) {
            existingSubcategory.pages.push(rest);
          } else {
            result[category].push({
              entity_subcategory,
              pages: [rest],
            });
          }
        } else {
          // Items without subcategories
          if (!result[category]) {
            result[category] = [];
          }
          result[category].push({
            entity_subcategory: null,
            pages: [rest],
          });
        }
      }
    });

    console.log('result', result);

    setPages(result);
  };

  return isLoading && userAccess === null ? (
    <Loader opacity={1}></Loader>
  ) : (
    <Flex minH="100vh" alignItems="stretch">
      {pages != null && <Sidebar route={pages} />}
      <Box
        flex="1 1 auto"
        pt={12}
        pb={8}
        px={6}
        overflowX="auto"
        pos="relative"
        ml={255}
      >
        <Outlet />
        <Box
          pos="absolute"
          top={6}
          right={6}
          zIndex={30}
          display={{ base: 'block', md: 'none' }}
        >
          <HamburgerMenu
            isOpen={isOpened}
            menuClicked={isOpened ? closeSidebar : openSidebar}
            width={18}
            height={15}
            strokeWidth={2}
            rotate={0}
            color={isOpened ? 'white' : 'black'}
            borderRadius={2}
            animationDuration={0.5}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default SidebarRoute;

const test = {
  flow: [
    {
      subcategory: null,
      pages: [
        {
          category: 'flow',
          subcategory: null,
          page: 'listing',
          pathname: '/listing',
          parent: false,
          sidebar: true,
        },
      ],
    },
    {
      subcategory: 'dealing',
      pages: [
        {
          category: 'flow',
          subcategory: 'dealing',
          page: 'first dealing',
          pathname: '/first_listing',
          parent: false,
          sidebar: true,
        },
        {
          category: 'flow',
          subcategory: 'dealing',
          page: 'second dealing',
          pathname: '/second_listing',
          parent: false,
          sidebar: true,
        },
      ],
    },
  ],
  report: [
    {
      subcategory: null,
      pages: [
        {
          category: 'flow',
          subcategory: null,
          page: 'listing',
          pathname: '/listing',
          parent: false,
          sidebar: true,
        },
        {
          category: 'flow',
          subcategory: null,
          page: 'listing',
          pathname: '/listing',
          parent: false,
          sidebar: true,
        },
      ],
    },
  ],
  summary: {
    category: 'summary',
    subcategory: null,
    page: 'summary',
    pathname: '/summary',
    parent: true,
    sidebar: true,
  },
  ideation: {
    category: 'ideation',
    subcategory: null,
    page: 'ideation',
    pathname: '/ideation',
    parent: true,
    sidebar: true,
  },
  database: {
    category: 'database',
    subcategory: null,
    page: 'database',
    pathname: '/database',
    parent: true,
    sidebar: true,
  },
};
