const { FormLabel, FormControl, Input, Text } = require('@chakra-ui/react');
const { Field, ErrorMessage } = require("formik");
const React = require("react");

export default function CustomInputForm({ values, label, id, isNumber=false, isUppercase=false }) {

  return (
    <FormControl isRequired>
      <FormLabel>{label}</FormLabel>
      <Field name={id}>
        {({ field }) => (
          <Input
            {...field}
            isRequired={true}
            id={id}
            name={id}
            value={values[id]}
            borderColor="gray.300"
            textTransform={isUppercase ? 'uppercase' : ''}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) && isNumber) {
                event.preventDefault();
              }
            }}
          />
        )}
      </Field>
      <ErrorMessage name={id}>
        {(msg) => (
          <Text color="red.500" mt={1} fontSize="sm">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  );
}
