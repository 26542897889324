import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import DateInputForm from 'components/fields/DateInputForm';
import { Form, Formik } from 'formik';
import useKol from 'hooks/useKol';
import useTimeline from 'hooks/useTimeline';

const FormComponent = ({ onClose }) => {
  const toast = useToast();

  const [sowList, setSow] = useState([]);
  const [timelineList, setTimeline] = useState([]);
  const [final_rate_card_agreement_id, setFinalRateCardId] = useState([]);
  const [slot, setSlot] = useState(0);
  const { kolData, kolDetailContract, getKolByPlatform, getKolDetailContract } =
    useKol();
  const { postTimeline } = useTimeline();

  const initialValues = {
    contract_id: '',
    final_rate_card_agreement_id: '',
    timelines: [],
  };

  useEffect(() => {
    let sow = [];

    if (kolDetailContract) {
      const data = kolDetailContract.contract_component;
      data.map((val) => {
        const res = val.final_rate_card_agreement.rate_card.sow;
        sow.push(res);
      });
    }

    setSow(sow);
  }, [kolDetailContract]);

  const handlePlatformChange = async (selectedPlatform) => {
    try {
      console.log(selectedPlatform);
      await getKolByPlatform(selectedPlatform);
    } catch (error) {
      console.error('Error fetching KOL data:', error);
    }
  };

  const handleKolChange = async (index) => {
    await getKolDetailContract(index);
  };

  const handleSowChange = async (index) => {
    const data = kolDetailContract.contract_component[index - 1];
    const existingTimelines = data?.timelines || [];
    const availableSlots = data.final_rate_card_agreement.slot;
    const rateCardId = data.final_rate_card_agreement.id;

    setTimeline(existingTimelines);
    setSlot(availableSlots);
    setFinalRateCardId(rateCardId);

    console.log('existingTimelines', existingTimelines);
  };

  const onSubmit = async (values) => {
    const transformedData = {
      contract_id: kolDetailContract.contract_id,
      final_rate_card_agreement_id: final_rate_card_agreement_id,
      timelines: values.timelines
        .filter((timeline) => timeline !== undefined)
        .map((timeline) => ({
          id: timeline.id,
          date: timeline.upload_date,
        })),
    };

    console.log('Transformed Data', transformedData);

    try {
      await postTimeline(transformedData);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.log(error)
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={3} spacing={5} mb="20px">
                <GridItem>
                  <FormControl>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      name="platform"
                      placeholder="Select platform"
                      onChange={(e) => {
                        handlePlatformChange(e.target.value);
                        setFieldValue('platform', e.target.value);
                      }}
                      value={values.platform}
                    >
                      <option value="tiktok">Tiktok</option>
                      <option value="instagram">Instagram</option>
                      <option value="twitter">Twitter</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>KOL</FormLabel>
                    <Select
                      placeholder="Select KOL"
                      name="kol_id"
                      onChange={(e) => {
                        handleKolChange(e.target.value);
                        setFieldValue('kol_id', e.target.value);
                      }}
                      style={{ textTransform: 'capitalize' }}
                      value={values.kol_id}
                    >
                      {kolData &&
                        kolData.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.username}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>SOW</FormLabel>
                      <Select
                        name="sow"
                        placeholder="Select SOW"
                        onChange={(e) => {
                          handleSowChange(e.target.selectedIndex);
                          setFieldValue('sow', e.target.value);
                        }}
                        value={values.sow}
                      >
                        {sowList &&
                          sowList.map((item, key) => (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </SimpleGrid>
              {slot != 0 && (
                <Text mb="10px" fontSize="md" fontWeight="800">
                  Timelines
                </Text>
              )}
              {Array.from({ length: slot }).map((_, index) => (
                <SimpleGrid
                  key={index}
                  columns={3}
                  spacingX={5}
                  spacingY={5}
                  mb="20px"
                >
                  <GridItem>
                    <FormControl>
                      <FormLabel>Slot Number</FormLabel>
                      <Input
                        readOnly
                        type="number"
                        value={index + 1}
                        borderColor="grey"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <DateInputForm
                        values={values}
                        label={'Upload Date'}
                        id={`${values}`} // Unique id for each DateInputForm
                        isArray={true}
                        maxDateOn={false}
                        minDateToday={true}
                        arrayIndex={index}
                        setFieldValue={setFieldValue}
                        initValue={
                          timelineList.length === null
                            ? null
                            : timelineList[index]?.date
                        }
                        timelineId={
                          timelineList.length === null
                            ? null
                            : timelineList[index]?.id
                        }
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
              ))}

              {slot != 0 && (
                <Box textAlign="right">
                  <Button colorScheme="purple" type="submit">
                    Save
                  </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
