import axios from 'axios';
import { useState } from 'react';

export default function useKolForm() {
  const [kolData, setKolData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState('');
  const url = process.env.REACT_APP_API_URL;

  const getFirstKolFormData = async (index) => {
    try {
      const { data } = await axios.get(`${url}/form/${index}/first-form`);

      const data_dealing = data.data.dealing;
      const data_utils = data.data;
      const kol_data = {
        username: data_dealing?.kol?.username ?? '',
        platform: data_dealing?.kol?.platform?.name ?? '',
        brand: data_dealing?.kol?.brand?.name ?? '',
        business_type:
          data_dealing?.kol_business_type_agreements?.business_type?.name ??
          '',
        pic: data_dealing?.user_issued_by?.name ?? '',
        contract_info: data_dealing?.cost?.rate_card ?? '',
        contract_number: data_dealing?.kol?.contract_number ?? '',
        banks: data_utils?.banks ?? [],
        dealing_rules: data_utils?.dealing_rules ?? '',
        payment_methods: data_utils?.payment_methods ?? '',
      };

      setKolData(kol_data);
      console.log(kol_data);
    } catch (error) {
      console.log(error);
      setError('Link invalid or user already filled the form');
    } finally {
      setLoading(false);
    }
  };
  
  const getSecondKolFormData = async (index) => {
    try {
      const { data } = await axios.get(`${url}/form/${index}/second-form`);
      
      const data_dealing = data.data.dealing;
      const data_utils = data.data;

      let total_transcation = 0;
      data_dealing.cost.rate_card.forEach((item) => {
        total_transcation +=
          parseFloat(item.final_rate_card_agreements.price) || 0;
      });

      const quotation_file = data_dealing?.contract?.contract_file?.find(
        (file) => file.file_type_configuration.name === 'quotation',
      )?.file_url;
      
      const spk_mou_file = data_dealing?.contract?.contract_file?.find(
        (file) =>
          file.file_type_configuration.name === 'spk_unsigned' ||
          file.file_type_configuration.name === 'mou_unsigned');

      const kol_data = {
        username: data_dealing?.kol?.username ?? '',
        platform: data_dealing?.kol?.platform?.name ?? '',
        brand: data_dealing?.kol?.brand?.name ?? '',
        business_type: data_dealing?.kol_business_type_agreements?.business_type?.name ?? '',
        pic: data_dealing?.user_assigned_to?.name ?? '',
        full_name: data_dealing?.kol?.kol_detail?.[0]?.full_name ?? '',
        email: data_dealing?.kol?.kol_detail?.[0]?.email ?? '',
        whatsapp_number: data_dealing?.kol?.kol_detail?.[0]?.whatsapp_number ?? '',
        home_address: data_dealing?.kol?.kol_detail?.[0]?.home_address ?? '',
        bank_name: data_dealing?.kol?.kol_detail?.[0]?.bank?.name ?? '',
        bank_account_name: data_dealing?.kol?.kol_detail?.[0]?.bank_account_name ?? '',
        bank_account_number: data_dealing?.kol?.kol_detail?.[0]?.bank_account_number ?? '',
        quotation_file: quotation_file ?? '',
        spk_mou_file:  spk_mou_file?.file_url ?? '',
        spk_mou_type: spk_mou_file?.file_type_configuration?.name ?? '',
        payment_methods: data_utils?.payment_methods ?? '',
        dealing_rules: data_utils?.dealing_rules ?? '',
        contract_total_transaction: total_transcation
      };

      setKolData(kol_data);
      console.log(kol_data);
      
    } catch (error) {
      console.log(error);
      setError('Link invalid or user already filled the form');
    } finally {
      setLoading(false);
    }
  };

  const postFirstKolForm = async (body, index) => {
    try {
      let { data } = await axios.post(`${url}/form/${index}/first-form`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  const postSecondKolForm = async (body, index) => {
    try {
      let { data } = await axios.post(`${url}/form/${index}/second-form`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    kolData,
    isLoading,
    isError,
    getFirstKolFormData,
    getSecondKolFormData,
    postFirstKolForm,
    postSecondKolForm,
  };
}
