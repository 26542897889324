import { useState } from 'react';
import api from 'utils/api';

export default function useTimeline() {
  const [timeline, setTimeline] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const getAllTimeline = async (month, year, platform) => {
    let value = [];
    try {
      let { data } = await api.get(
        `/timeline/data/all?month=${month}&year=${year}&platform=${platform}`,
      );

      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id;
        const start = new Date(val.date);
        const end = new Date(val.date);
        const username = val.contract_component?.contract?.kol?.username ?? '';
        const contract_id = val.contract_component?.contract_id ?? '';
        const final_rate_card_agreement_id =
          val.contract_component?.final_rate_card_agreement?.id ?? '';
        const type = 
        val.contract_component?.final_rate_card_agreement?.rate_card?.type ??
        '';
        const platform_content = platform;
        const is_uploaded = val.contract_component?.is_done ?? '';
        const title = `${username} | ${type}`;

        const object = {
          id,
          start,
          end,
          title,
          username,
          contract_id,
          final_rate_card_agreement_id,
          type,
          platform_content,
          is_uploaded,
        };
        value.push(object);
      });

      console.log("timeline", value)

      setTimeline(value);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getTimelineById = async () => {
    try {
      let { data } = await api.get(`/timeline/kol-categories`);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const editTimelineById = async (index, body) => {
    try {
      console.log('id', index, "body");
      setLoading(true)
      let { data } = await api.post(`/timeline/${index}`, body);

      console.log(data);
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  const postTimeline = async (body) => {
    try {
      let { data } = await api.post(`/timeline`, body);

      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    error,
    timeline,
    getAllTimeline,
    getTimelineById,
    editTimelineById,
    postTimeline,
  };
}
