export const columnsDataDevelopment = [
  {
    Header: 'APPROVAL DATE',
    accessor: 'approval_date',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  // {
  //   Header: 'RATE CARD',
  //   accessor: 'rate_card',
  // },
  // {
  //   Header: 'ADD COST',
  //   accessor: 'add_cost',
  // },
  {
    Header: 'KOL CATEGORY',
    accessor: 'kol_category',
  },
  {
    Header: 'NOTE',
    accessor: 'note',
  },
  {
    Header: 'DEALING TYPE',
    accessor: 'dealing_type',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
