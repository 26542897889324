import { useState } from 'react';
import DatePicker from 'react-date-picker';
import '../../assets/css/DatePicker.css';
import { FormLabel, FormControl, Text } from '@chakra-ui/react';
import { Field, ErrorMessage } from 'formik';
import React from 'react';

export default function DateInputForm({
  values,
  label,
  id,
  setFieldValue,
  isArray,
  arrayIndex = 0,
  initValue = null,
  timelineId = 0,
  maxDateOn = true,
  minDateToday = true,
}) {
  const [value, onChange] = useState(
    initValue === null ? null : new Date(initValue),
  );

  console.log('value', value);
  // Function to format date as 'YYYY-MM-DD'
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    onChange(date);
    const formattedDate = formatDate(date); // Format the date to 'YYYY-MM-DD'
    setFieldValue(id, formattedDate); // Update Formik field value with the formatted date
  };

  const handleDateChangeArray = (date) => {
    onChange(date);
    const formattedDate = formatDate(date); // Format the date to 'YYYY-MM-DD'
    const index = initValue === null ? null : timelineId;
    setFieldValue(`timelines[${arrayIndex}].upload_date`, formattedDate); // Update Formik field value with the formatted date
    setFieldValue(`timelines[${arrayIndex}].id`, index);
  };

  return (
    <FormControl isRequired>
      <FormLabel>{label}</FormLabel>
      <Field name={id}>
        {({ field, form }) => (
          <div>
            <DatePicker
              // minDate={minDateToday ? new Date() : new Date(1930, 0, 1)}
              maxDate={maxDateOn && new Date()}
              onChange={isArray ? handleDateChangeArray : handleDateChange}
              value={value}
              clearIcon={null}
              format={'dd-MM-y'} // The display format
            />
          </div>
        )}
      </Field>
      <ErrorMessage name={id}>
        {(msg) => (
          <Text color="red.500" mt={1} fontSize="sm">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  );
}
