export const columnsDataDevelopment = [
  {
    Header: 'SUBMITTED DATE',
    accessor: 'submitted_date',
  },
  {
    Header: 'SHIPPING ID',
    accessor: 'shipping_id',
  },
  {
    Header: 'FULL NAME',
    accessor: 'full_name',
  },
  {
    Header: 'ADDRESS',
    accessor: 'address',
  },
  {
    Header: 'PHONE NUMBER',
    accessor: 'phone_number',
  },
  {
    Header: 'PRODUCTS',
    accessor: 'products',
  },
  {
    Header: 'URGENT',
    accessor: 'urgent',
  },
  {
    Header: 'SHIPPING TYPE',
    accessor: 'shipping_type',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'TRACKING NUMBER',
    accessor: 'tracking_number',
  },
  {
    Header: 'EXPEDITION',
    accessor: 'expedition',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
