// ideation add form js
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import ImageResize from 'quill-image-resize-module-react';

import React, { useEffect } from 'react';
import { useCallback, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import useIdeation from 'hooks/useIdeation';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Select as SelectChakra,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import CustomInputForm from 'components/fields/TextInputForm';
import { useNavigate } from 'react-router-dom';

Quill.register('modules/imageResize', ImageResize);

export default function IdeationAddForm(props) {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    category,
    product,
    postImageIdeation,
    getBriefFormUtils,
    postIdeation,
  } = useIdeation();
  const reactQuillRef = useRef(null);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    getBriefFormUtils();
  }, []);

  // Custom function to check for the Quill placeholder value
  const isQuillEmpty = (value) => {
    const trimmedValue = value.replace(/<(.|\n)*?>/g, '').trim(); // Remove HTML tags and trim whitespace
    return trimmedValue === '';
  };

  const validationSchema = Yup.object().shape({
    category: Yup.array()
      .min(1, 'At least one category is required')
      .required('Category is required'),
    product: Yup.array()
      .min(1, 'At least one product is required')
      .required('Product is required'),
    detail: Yup.string()
      .required('Detail is required')
      .test(
        'is-quill-empty',
        'Detail is required',
        (value) => !isQuillEmpty(value),
      ),
  });

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const formData = new FormData();
        formData.append('brief_file', input.files[0]);

        const url = await postImageIdeation(5, formData);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditor().getSelection();
          range && quill.getEditor().insertEmbed(range.index, 'image', url);
        }
      }
    };
  }, []);

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title,
      hook: values.hook,
      detail: values.detail,
      attached_categories: values.category.map((categoryId) => ({
        brief_category_id: categoryId,
      })),
      attached_products: values.product.map((categoryId) => ({
        product_id: categoryId,
      })),
      note: null,
    };

    try {
      await postIdeation(payload);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      navigate('/ideation/kol');
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const initialValues = {
    title: '',
    hook: '',
    detail: '',
    category: category,
    product: product,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
        }) => (
          <>
            <Form>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4}>
                    <CustomInputForm
                      values={values}
                      label={'Title'}
                      id={'title'}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    mb={4}
                    isRequired={true}
                    isInvalid={errors.category && touched.category}
                  >
                    <FormLabel>Category</FormLabel>
                    <Field name="category">
                      {({ field, form }) => (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={
                            category &&
                            category.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          }
                          onChange={(selectedOptions) =>
                            setFieldValue(
                              field.name,
                              // @ts-ignore
                              selectedOptions.map((option) => option.value),
                            )
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: 'transparent',
                              borderColor: '#a6a7a7',
                            }),
                          }}
                        />
                      )}
                    </Field>
                    {errors.category && touched.category ? (
                      <Text color="red.500" mt={2}>
                        {errors.category}
                      </Text>
                    ) : null}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <SimpleGrid
                columns={{ base: 1, md: 1 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                <GridItem>
                  <FormControl mb={4}>
                    <CustomInputForm
                      values={values}
                      label={'Hook'}
                      id={'hook'}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 1, md: 4 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                {/* Row 3 */}
                <GridItem colSpan={4}>
                  <FormControl
                    mb={4}
                    isRequired={true}
                    isInvalid={errors.product && touched.product}
                  >
                    <FormLabel>Product</FormLabel>
                    <Field name="product">
                      {({ field, form }) => (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={
                            product &&
                            product.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          }
                          onChange={(selectedOptions) =>
                            setFieldValue(
                              field.name,
                              // @ts-ignore
                              selectedOptions.map((option) => option.value),
                            )
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: 'transparent',
                              borderColor: '#a6a7a7',
                            }),
                          }}
                        />
                      )}
                    </Field>
                    {errors.product && touched.product ? (
                      <Text color="red.500" mt={2}>
                        {errors.product}
                      </Text>
                    ) : null}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <SimpleGrid
                columns={{ base: 1, md: 5 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                <GridItem colSpan={5}>
                  <FormControl
                    mb={4}
                    isRequired={true}
                    isInvalid={errors.detail && touched.detail}
                  >
                    <FormLabel>Detail</FormLabel>
                    <ReactQuill
                      ref={reactQuillRef}
                      theme="snow"
                      placeholder="Start writing..."
                      modules={{
                        toolbar: {
                          container: [
                            [{ header: '1' }, { header: '2' }, { font: [] }],
                            [{ size: [] }],
                            [
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                            ],
                            [
                              { list: 'ordered' },
                              { list: 'bullet' },
                              { indent: '-1' },
                              { indent: '+1' },
                            ],
                            ['link', 'image', 'video'],
                            ['code-block'],
                            ['clean'],
                          ],
                          handlers: {
                            image: imageHandler,
                          },
                        },
                        clipboard: {
                          matchVisual: false,
                        },
                        imageResize: {
                          parchment: Quill.import('parchment'),
                          modules: ['Resize', 'DisplaySize'],
                        },
                      }}
                      formats={[
                        'header',
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'code-block',
                        'width',
                      ]}
                      value={values.detail} // Bind the value to Formik's state
                      onChange={(detail) => setFieldValue('detail', detail)} // Update Formik's state on change
                    />
                    {errors.detail && touched.detail ? (
                      <Text color="red.500" mt={2}>
                        {errors.detail}
                      </Text>
                    ) : null}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Flex justify="flex-end" mt="5px">
                <Button
                  onClick={() => navigate('/ideation/kol')}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  mx="5px"
                  disabled={isSubmitting}
                  bg="grey.300"
                  color="purple.600"
                  _hover={{ bg: 'gray.200' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
