// @ts-nocheck
import {
  Flex,
  Spacer,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { MdCircle, MdEdit, MdOutlineDelete } from 'react-icons/md';
import React, { useMemo, useState } from 'react';
import FormComponent from './FormComponent';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DetailCard from 'components/card/DetailCard';

export default function ListingTable(props) {
  const { columnsData, tableData, listingOptions } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const approvedColor = useColorModeValue('green.500', 'white');
  const onHoldColor = useColorModeValue('gray.500', 'white');
  const rejectedColor = useColorModeValue('red.500', 'white');
  const iconColor = useColorModeValue('blue.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  function getFirstFiveWords(str) {
    const string = str.trim().split(/\s+/);

    if (string.length > 5) {
      const words = str.split(' ');
      const firstFiveWords = words.slice(0, 5);
      return { word: firstFiveWords.join(' '), overlay: true };
    } else {
      return { word: str, overlay: false };
    }
  }

  function handleRowEdit(row) {
    setSelectedRow(row.original);
    onOpen();
    console.log('row', row.id);
  }

  function handleRowDelete(row) {
    console.log('row', row.id);
  }

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'LISTING_DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '140px', md: '140px', lg: '140px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'USERNAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value.link} target="_blank">
                            {cell.value.socmed_id}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'PLATFORM') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'KOL_CATEGORY') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'NOTE') {
                      const words = getFirstFiveWords(cell.value);
                      data = (
                        <>
                          <span style={{ color: 'black' }}>{words.word}</span>
                          <Popover>
                            <PopoverTrigger>
                              {words.overlay ? (
                                <button
                                  onClick={onOpen}
                                  style={{ color: 'blue' }}
                                >
                                  &nbsp;readmore
                                </button>
                              ) : (
                                <></>
                              )}
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverBody color="black">
                                {cell.value}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '200px', md: '200px', lg: '200px' }}
                          maxW={{ sm: '100px', md: '250px', lg: '300px' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'CPM') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'STATUS') {
                      let iconColor = textColor;
                      if (cell.value === 'approved') {
                        iconColor = approvedColor;
                      } else if (cell.value === 'pending approval') {
                        iconColor = onHoldColor;
                      } else {
                        iconColor = rejectedColor;
                      }

                      data = (
                        <Flex align="center">
                          <Icon
                            as={MdCircle}
                            color={iconColor}
                            w="24px"
                            h="24px"
                            me="10px"
                          />
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="500"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '200px', md: '200px', lg: '200px' }}
                          maxW={{ sm: '100px', md: '250px', lg: '300px' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'ISSUED_BY') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ACTION') {
                      const statusCell = row.cells.find(
                        (c) => c.column.Header === 'STATUS',
                      );
                      const isApproved =
                        statusCell && statusCell.value === 'approved';

                      data = !isApproved ? (
                        <Button
                          onClick={() => handleRowEdit(row)}
                          colorScheme="blue"
                          variant="ghost"
                          size="sm"
                          leftIcon={<Icon as={MdEdit} w="20px" h="20px" />}
                        >
                          {/* Edit */}
                        </Button>
                      ) : null;
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth={{ base: '800px' }}>
            <ModalHeader>KOL Price Negotiation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormComponent
                cell={selectedRow}
                listingOptions={listingOptions}
                onClose={onClose}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Card>
    </>
  );
}
