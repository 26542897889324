// @ts-nocheck
/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import React, { useMemo, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

export default function DealingTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'DEAL DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'KOL ID') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'USERNAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value.link} target="_blank">
                            {cell.value.socmed_id}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'PLATFORM') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'URL') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value} target="_blank">
                            {cell.value}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'DEALING TYPE') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
