import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
// import ImageResize from 'quill-image-resize-module-react';

import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Field, Form, Formik } from 'formik';
import useIdeation from 'hooks/useIdeation';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CustomInputForm from 'components/fields/TextInputForm';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import { useAuthContext } from 'contexts/AuthContext';

// Quill.register('modules/imageResize', ImageResize);

export default function IdeationEditForm() {
  const { id } = useParams();
  const { userRole } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { ideationById, getIdeationById, postIdeationRevision } = useIdeation();
  const reactQuillRef = useRef(null);
  const animatedComponents = makeAnimated();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ideationData = await getIdeationById(id, userRole);
        setInitialVariables(ideationData, 'category');
        setInitialVariables(ideationData, 'product');
        setCurrentStatus(ideationData.status);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const setInitialVariables = (ideationData, type) => {
    const mapData = (data) =>
      data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

    const setSelectedFunction =
      type === 'category' ? setSelectedCategories : setSelectedProducts;

    const initialSelected = mapData(ideationData[type]);
    setSelectedFunction(initialSelected);
  };

  const validationSchema = Yup.object().shape({
    note: Yup.string().required('Note is required'),
  });

  const handleSubmit = async (values, type) => {
    let payload;
    let toast_msgs;

    if (type === 'approved') {
      payload = {
        status: 'approved',
        note: null,
      };
      toast_msgs = 'Brief approved!';
    } else if (type === 'revision') {
      payload = {
        status: 'need revision',
        note: values.note,
      };
      toast_msgs = 'Brief revision submitted!';
    }

    try {
      await postIdeationRevision(id, payload);
      toast({
        title: toast_msgs,
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      navigate('/ideation/manager');
    } catch (error) {
      toast({
        title: 'Cannot update form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const initialValues = {
    title: ideationById?.title || '',
    hook: ideationById?.hook || '',
    detail: ideationById?.detail || '',
    category: selectedCategories,
    product: selectedProducts,
  };

  return ideationById === null ? (
    <Loader opacity={1}></Loader>
  ) : (
    <>
      <Text fontWeight="bold" fontSize="34px" mb={5}>
        Ideation
      </Text>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ isSubmitting, values }) => (
          <Form>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacingX={5}
              spacingY={5}
              mb="10px"
            >
              <GridItem>
                <FormControl mb={4}>
                  <FormLabel>Title</FormLabel>
                  <Input value={values.title} readOnly />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={4}>
                  <FormLabel>Category</FormLabel>
                  <Field name="category">
                    {({ field }) => (
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        value={selectedCategories}
                        isDisabled={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            borderColor: '#a8a6a6',
                          }),
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
              </GridItem>
            </SimpleGrid>

            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              spacingX={5}
              spacingY={5}
              mb="10px"
            >
              <GridItem>
                <FormControl mb={4}>
                  <FormLabel>Hook</FormLabel>
                  <Input value={values.hook} readOnly />
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: 1, md: 4 }}
              spacingX={5}
              spacingY={5}
              mb="10px"
            >
              <GridItem colSpan={4}>
                <FormControl mb={4}>
                  <FormLabel>Product</FormLabel>
                  <Field name="product">
                    {({ field }) => (
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        value={selectedProducts}
                        isDisabled={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            borderColor: '#a8a6a6',
                          }),
                        }}
                      />
                    )}
                  </Field>
                </FormControl>
              </GridItem>
            </SimpleGrid>

            <SimpleGrid
              columns={{ base: 1, md: 5 }}
              spacingX={5}
              spacingY={5}
              mb="10px"
            >
              <GridItem colSpan={5}>
                <FormControl mb={4}>
                  <FormLabel>Detail</FormLabel>
                  <ReactQuill
                    ref={reactQuillRef}
                    theme="snow"
                    modules={{}}
                    value={values.detail}
                    readOnly={true}
                    style={{ borderColor: '#a8a6a6', borderWidth: '0.8px' }}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>

            {console.log(
              currentStatus === 'need revision',
              ideationById.note != '',
            )}

            {!ideationById.isApproved && ideationById.note != '' && (
              <SimpleGrid
                columns={{ base: 1, md: 5 }}
                spacingX={5}
                spacingY={5}
                mb="10px"
              >
                <GridItem colSpan={5}>
                  <FormControl mb={4}>
                    <FormLabel>Latest Revision Note</FormLabel>
                    <Textarea value={ideationById.note} readOnly />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            )}

            <Text color="black" mt={2}>
              Created by: {ideationById.created_by} at {ideationById.created_at}
            </Text>
            <Text color="black" mt={2} textTransform="capitalize">
              Status: {ideationById.status}
            </Text>

            {currentStatus === 'approved' ? (
              <Flex justify="flex-end" mt="12px">
                <Button
                  onClick={() => navigate('/ideation/manager')}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  mx="5px"
                  disabled={isSubmitting}
                  bg="grey.300"
                  color="purple.600"
                  border="1px"
                  borderColor="purple.600"
                  _hover={{ bg: 'gray.300' }}
                >
                  Close
                </Button>
              </Flex>
            ) : (
              <Flex justify="flex-end" mt="12px">
                <Button
                  onClick={() => navigate('/ideation/manager')}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  disabled={isSubmitting}
                  bg="grey.300"
                  color="purple.600"
                  _hover={{ bg: 'gray.300' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => onOpen()}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  mx="5px"
                  disabled={isSubmitting}
                  bg="red.500"
                  color="white"
                  _hover={{ bg: 'red.700' }}
                >
                  Revision
                </Button>
                <Button
                  onClick={() => handleSubmit(values, 'approved')}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="10%"
                  h="35"
                  mt="12px"
                  _hover={{ bg: 'purple.700' }}
                  disabled={isSubmitting}
                >
                  Approve
                </Button>
              </Flex>
            )}
          </Form>
        )}
      </Formik>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={{ base: '800px' }}>
          <ModalHeader>Brief Revision</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{ note: '' }}
              onSubmit={(values) => {
                handleSubmit(values, 'revision');
                onClose();
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <FormControl mb={4}>
                    <FormLabel>Revision</FormLabel>
                    <Field
                      as={Textarea}
                      name="note"
                      placeholder="Enter revision"
                      rows={2}
                    />
                  </FormControl>

                  <ModalFooter>
                    <Button
                      bg="grey.300"
                      fontSize="sm"
                      color="purple.600"
                      type="submit"
                      mr={2}
                    >
                      Cancel
                    </Button>
                    <Button
                      bg="purple.600"
                      color="white"
                      fontSize="sm"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
