import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  useToast,
  Select,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import useDealing from 'hooks/useDealing';
import CustomUploadForm from 'components/fields/UploadForm';

const FormComponent = ({ onClose, id }) => {
  const {
    getSecondDealingProcessForm,
    secondDealingProcessForm,
    postSecondDealingVerification,
  } = useDealing();
  const toast = useToast();

  useEffect(() => {
    getSecondDealingProcessForm();
  }, []);

  const initialValues = {
    username: '',
    platform: '',
    kol_category: '',
    rate_card: '',
  };

  const onSubmit = async (values) => {
    const formData = new FormData();

    formData.append('payment_method_id', values.payment_method_id);
    formData.append('dealing_rule_id', values.dealing_rule_id);
    formData.append('invoice', values.invoice_file);
    formData.append('quotation', values.quotation_file);

    // @ts-ignore
    console.table([...formData]);

    try {
      await postSecondDealingVerification(formData, id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid
                columns={{ base: 2, md: 2 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                <GridItem>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Payment Method</FormLabel>
                    <Select
                      name="payment_method"
                      placeholder="Select payment method"
                      value={values.payment_method_id}
                      // borderColor={borderColor}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('payment_method_id', e.target.value);
                      }}
                    >
                      {secondDealingProcessForm?.payment_method?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Contract Dealing Rule</FormLabel>
                    <Select
                      name="contract_dealing_rule"
                      placeholder="Select dealing rule"
                      value={values.dealing_rule_id}
                      // borderColor={borderColor}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('dealing_rule_id', e.target.value);
                      }}
                    >
                      {secondDealingProcessForm?.dealing_rule?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 2, md: 2 }}
                spacingX={5}
                spacingY={5}
                mb="20px"
              >
                <GridItem>
                  <FormControl mb={4}>
                    <CustomUploadForm
                      label="Upload Quotation"
                      id={'quotation_file'}
                      values={values}
                      file_type={'application/pdf'}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <CustomUploadForm
                      label="Upload Invoice"
                      id={'invoice_file'}
                      values={values}
                      file_type={'application/pdf'}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Box textAlign="right">
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
