// src/AuthContext.js
import useAuthorization from 'hooks/useAuthorization';
import React, { createContext, useContext } from 'react';

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
    const {user, userRole, userAccess, isLoading, setUser, setUserAccess, postLogin, postLogout} = useAuthorization()
    return (
        <AuthContext.Provider value={{ user, userRole, userAccess, isLoading, setUser, setUserAccess, postLogin, postLogout}}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuthContext() {
    return useContext(AuthContext);
}