
import {
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Button,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import React, { useMemo, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import useDealing from 'hooks/useDealing';
import FormComponent from './FormComponent';

export default function DealingTable(props) {
  const { columnsData, tableData } = props;
  const [selectedRowId, setRowId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  function handleRowEdit(id) {
    onOpen();
    setRowId(id);
  }

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'SUBMITTED AT') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'KOL ID') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'USERNAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value.link} target="_blank">
                            {cell.value.socmed_id}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'FULL NAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'PLATFORM') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'TOTAL CONTRACT PRICE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'NIK') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'IDENTITY TYPE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'IDENTITY DOC') {
                      data = (
                        <Text
                          color="purple.500"
                          cursor="pointer"
                          textDecoration="underline"
                        >
                          <a
                            href={cell.value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            show
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'CONTRACT DEALING RULE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'GROSS/NETT') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'TAX TYPE') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'TAX PERCENTAGE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}%
                        </Text>
                      );
                    } else if (
                      cell.column.Header === 'TOTAL CONTRACT PRICE AFTER TAX'
                    ) {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          Rp{cell.value}
                        </Text>
                      );
                    } else if (
                      cell.column.Header === 'SIGNED CONTRACT DOCUMENT'
                    ) {
                      data =
                        cell.value !== '-' ? (
                          <Text
                            color="purple.500"
                            cursor="pointer"
                            textDecoration="underline"
                          >
                            <a
                              href={cell.value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              show
                            </a>
                          </Text>
                        ) : (
                          cell.value
                        );
                    } else if (cell.column.Header === 'ACTION') {
                      data = (
                        <Text
                          color="purple.500"
                          onClick={() => handleRowEdit(row.original.id)}
                          cursor="pointer"
                          textDecoration="underline"
                        >
                          process payment
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth={{ base: '600px' }}>
            <ModalHeader>Process Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormComponent id={selectedRowId} onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Card>
    </>
  );
}
