import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { MdAddCircle } from 'react-icons/md';
import { ErrorMessage, Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import useListing from 'hooks/useListing';

const validationSchema = Yup.object({
  rateCards: Yup.array()
    .min(1, 'Must select at least one category')
    .required('This field is required'),
  addCosts: Yup.array()
    .min(0, 'Must select at least one category')
    .required('This field is required'),
});

const FormComponent = ({ onClose, listingModal }) => {
  const toast = useToast();
  const [platform, setPlatform] = useState('');
  const [availableRateCards, setAvailableRateCards] = useState([]);
  const [availableCost, setAvailableCost] = useState([]);
  const [kolCategoryIndex, setKolCategoryIndex] = useState(0);
  const { postListingKol } = useListing();

  const handleRateCardAdd = (sow_type_list, values, setFieldValue) => {
    setFieldValue('rateCards', [
      ...values.rateCards,
      { sow_type_list, price: '', rate_card_note: '' },
    ]);
  };

  const handleCostAdd = (sow_type_list, values, setFieldValue) => {
    setFieldValue('addCosts', [
      ...values.addCosts,
      { sow_type_list, price: '', add_cost_note: '' },
    ]);
  };

  const handlePlatformChange = (event, setFieldValue, values) => {
    const selectedPlatform = event.target.value;
    setPlatform(selectedPlatform);
    setFieldValue('platform', selectedPlatform);
    setFieldValue('rateCards', []);
    setFieldValue('addCosts', []);

    const rateCards_ = listingModal.sow_types.filter(
      (e) => e.platform_id == selectedPlatform && e.sow_category_id == 1,
    );

    const addCost_ = listingModal.sow_types.filter(
      (e) => e.platform_id == selectedPlatform && e.sow_category_id == 2,
    );

    setAvailableRateCards(rateCards_);
    setAvailableCost(addCost_);

    // Auto-fill link if username is already filled
    if (values.username) {
      let link = '';
      switch (selectedPlatform) {
        case '1':
          link = `https://tiktok.com/@${values.username}`;
          break;
        case '2':
          link = `https://instagram.com/${values.username}`;
          break;
        case '3':
          link = `https://x.com/${values.username}`;
          break;
        default:
          break;
      }
      setFieldValue('link', link);
    }
  };

  const handleUsernameChange = (event, setFieldValue, values) => {
    const username = event.target.value;
    setFieldValue('username', username);

    // Auto-fill link if platform is already selected
    if (values.platform) {
      let link = '';
      switch (values.platform) {
        case '1':
          link = `https://tiktok.com/@${username}`;
          break;
        case '2':
          link = `https://www.instagram.com/${username}`;
          break;
        case '3':
          link = `https://x.com/${username}`;
          break;
        default:
          break;
      }
      setFieldValue('link', link);
    }
  };

  const handleLinksChange = (event, setFieldValue) => {
    const link = event.target.value;
    let username = '';
    let selectedPlatform = '';

    if (link.includes('tiktok.com')) {
      selectedPlatform = '1';
      username = link.split('@')[1];
    } else if (link.includes('instagram.com')) {
      selectedPlatform = '2';
      username = link.split('instagram.com/')[1];
    } else if (link.includes('x.com')) {
      selectedPlatform = '3';
      username = link.split('x.com/')[1];
    }

    setPlatform(selectedPlatform);

    setFieldValue('username', username);
    setFieldValue('platform', selectedPlatform);
    setFieldValue('link', link);

    const rateCards_ = listingModal.sow_types.filter(
      (e) => e.platform_id == selectedPlatform && e.sow_category_id == 1,
    );

    const addCost_ = listingModal.sow_types.filter(
      (e) => e.platform_id == selectedPlatform && e.sow_category_id == 2,
    );

    setAvailableRateCards(rateCards_);
    setAvailableCost(addCost_);
  };

  const initialValues = {
    username: '',
    platform: '',
    link: '',
    kol_category: '',
    rateCards: [],
    rate_card_note: '',
    addCosts: [],
    note: '',
  };

  const onSubmit = async (values) => {
    const transformedData = {
      cost: {
        rate_card: values.rateCards.map((rateCard) => ({
          sow_type_id: rateCard.sow_type_list.id,
          price: Number(rateCard.price),
          sow: rateCard.sow,
          note: rateCard.rate_card_note,
        })),
        add_cost: values.addCosts.map((cost) => ({
          sow_type_id: cost.sow_type_list.id,
          price: cost.price,
          note: cost.add_cost_note,
        })),
      },
      kol: {
        username: values.username,
        platform_id: Number(values.platform),
        link: values.link,
        category: kolCategoryIndex,
        current_status: 1,
      },
      note: values.note || '',
    };

    console.log('Transformed Data', transformedData);

    try {
      await postListingKol(transformedData);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setTimeout(() => {
        onClose();
      }, 3000); // Same duration as the toast
      window.location.reload();
    } catch (error) {
      toast({
        title: 'Cannot sending form or user already exist!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={3} spacingX={5} spacingY={5} mb="20px">
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Username</FormLabel>
                    <Input
                      name="username"
                      placeholder="Enter username"
                      value={values.username}
                      onChange={(e) =>
                        handleUsernameChange(e, setFieldValue, values)
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      name="platform"
                      placeholder="Select platform"
                      onChange={(e) =>
                        handlePlatformChange(e, setFieldValue, values)
                      }
                      value={values.platform}
                      textTransform="capitalize"
                    >
                      {listingModal &&
                        listingModal.platforms.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4} isRequired>
                    <FormLabel>KOL Category</FormLabel>
                    <Select
                      name="kol_category"
                      placeholder="Select KOL category"
                      onChange={(e) => {
                        handleChange(e); // Call handleChange with the event
                        setKolCategoryIndex(e.target.selectedIndex); // Set the index
                      }}
                      value={values.kol_category}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {listingModal &&
                        listingModal.kol_categories.map((item, key) => (
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>

                {/* Row 2 */}
                <GridItem colSpan={3}>
                  <FormControl mb={4} isRequired>
                    <FormLabel>Links</FormLabel>
                    <Input
                      name="link"
                      placeholder="Enter or select link"
                      value={values.link}
                      onChange={(e) => handleLinksChange(e, setFieldValue)}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              {platform && (
                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Rate Card
                    </FormLabel>
                    <ErrorMessage
                      name="rateCards"
                      render={(msg) => (
                        <div style={{ color: 'red' }}>{msg}</div>
                      )}
                    />
                  </Box>
                  <Box>
                    <FormControl mb={4}>
                      <Menu>
                        <MenuButton as={Button} leftIcon={<MdAddCircle />}>
                          Add
                        </MenuButton>
                        <MenuList>
                          {availableRateCards.map((option) => (
                            <MenuItem
                              textTransform="capitalize"
                              key={option.id}
                              onClick={() =>
                                handleRateCardAdd(option, values, setFieldValue)
                              }
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </FormControl>
                  </Box>
                </Flex>
              )}

              <FieldArray name="rateCards">
                {({ remove, push }) => (
                  <>
                    {values.rateCards.map((rateCard, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input
                            value={rateCard.sow_type_list.name}
                            textTransform="capitalize"
                            readOnly
                          />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isRequired>
                            <FormLabel>Price</FormLabel>
                            <Flex>
                              <Field
                                name={`rateCards[${index}].price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                              <Button
                                ml={2}
                                colorScheme="red"
                                onClick={() => {
                                  remove(index);
                                  // setAvailableRateCards([
                                  //   ...availableRateCards,
                                  //   rateCard.type,
                                  // ]);
                                }}
                              >
                                Remove
                              </Button>
                            </Flex>
                          </FormControl>
                        </GridItem>
                        {/* Row 2 */}
                        <GridItem colSpan={3}>
                          <FormControl>
                            <FormLabel>SOW</FormLabel>
                            <Field
                              name={`rateCards[${index}].sow`}
                              as={Input}
                              placeholder="Enter SOW"
                            />
                          </FormControl>
                        </GridItem>
                        {/* Row 2 */}
                        <GridItem colSpan={3}>
                          <FormControl mb={4}>
                            <FormLabel>Note Rate Card</FormLabel>
                            <Field
                              name={`rateCards[${index}].rate_card_note`}
                              as={Input}
                              placeholder="Enter price"
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              {platform && (
                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Additional Cost
                    </FormLabel>
                  </Box>
                  <Box>
                    <FormControl mb={4}>
                      <Menu>
                        <MenuButton as={Button} leftIcon={<MdAddCircle />}>
                          Add
                        </MenuButton>
                        <MenuList>
                          {availableCost.map((option) => (
                            <MenuItem
                              textTransform="capitalize"
                              key={option.id}
                              onClick={() =>
                                handleCostAdd(option, values, setFieldValue)
                              }
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </FormControl>
                  </Box>
                </Flex>
              )}

              <FieldArray name="addCosts">
                {({ remove, push }) => (
                  <>
                    {values.addCosts.map((cost, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input
                            value={cost.sow_type_list.name}
                            readOnly
                            textTransform="capitalize"
                          />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormLabel>Price</FormLabel>
                          <Flex>
                            <Field
                              name={`addCosts[${index}].price`}
                              as={Input}
                              placeholder="Enter price"
                            />
                            <Button
                              ml={2}
                              colorScheme="red"
                              onClick={() => {
                                remove(index);
                                setAvailableCost([...availableCost, cost.type]);
                              }}
                            >
                              Remove
                            </Button>
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={3}>
                          <FormControl mb={4}>
                            <FormLabel>Note Add Cost</FormLabel>
                            <Field
                              name={`addCosts[${index}].add_cost_note`}
                              as={Input}
                              placeholder="Enter price"
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              <SimpleGrid columns={3} mb="20px">
                <GridItem colSpan={3}>
                  <FormControl mb={4}>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Note
                    </FormLabel>
                    <Field name="note" as={Input} placeholder="Enter notes" />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Box textAlign="right">
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
