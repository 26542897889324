// Loader.jsx
import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const Loader = ({ opacity = 0.8 }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor={`rgba(255, 255, 255, ${opacity})`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="9999"
    >
      <Spinner
        size="xl"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="purple.500"
      />
    </Box>
  );
};

export default Loader;
