export const columnsDataDevelopment = [
  {
    Header: 'VERIFIED AT',
    accessor: 'verified_at',
  },
  {
    Header: 'KOL ID',
    accessor: 'kol_id',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  {
    Header: 'URL',
    accessor: 'url',
  },
  {
    Header: 'DEALING TYPE',
    accessor: 'dealing_type',
  },
  {
    Header: 'CONTRACT DOCUMENT TYPE',
    accessor: 'contract_document_type',
  },
  {
    Header: 'DOCUMENT',
    accessor: 'document',
  },
];
