const {
  FormLabel,
  FormControl,
  Button,
  Input,
  Text,
} = require('@chakra-ui/react');
const { Field } = require("formik");
const React = require("react");


export default function CustomUploadForm({
  values,
  label,
  id,
  file_type = 'image/png, image/jpeg, image/jpg, pdf',
  isRequired = true,
}) {
  return (
    <FormControl id="file-upload" mb={4} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Button
        as="label"
        htmlFor={id}
        colorScheme="purple"
        variant="outline"
        cursor="pointer"
        width="100%"
      >
        Choose File
      </Button>
      <Field name={id}>
        {({ form }) => (
          <>
            <Input
              id={id}
              name={id}
              type="file"
              accept={file_type}
              isRequired={true}
              onChange={(event) => {
                form.setFieldValue(id, event.currentTarget.files[0]);
              }}
              style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                padding: 0,
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                border: 0,
              }}
            />
            {form.errors[id] && form.touched[id] && (
              <Text color="red.500">{form.errors[id]}</Text>
            )}
          </>
        )}
      </Field>
      {values[id] && (
        <Text mt={2} color="gray.600">
          Selected file: {values[id].name}
        </Text>
      )}
    </FormControl>
  );
}