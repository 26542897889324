// Chakra imports
import {
  Box,
  Flex,
  Spacer,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { columnsDataDevelopment } from './variables/columnsData';
// @ts-ignore
import React, { useEffect } from 'react';
import Pagination from 'components/footer/Pagination';
import Filter from 'components/menu/FilterMenu';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import ShippingTable from './ShippingTable';
import FormComponent from './FormComponent';
import useListing from 'hooks/useListing';
import Loader from 'components/loader/Loader';
import { useLocation } from 'react-router-dom';
import useShipping from 'hooks/useShipping';
import { useAuthContext } from 'contexts/AuthContext';
// @ts-ignore
import tableDataShipping from './variables/tableDataShipping.json';

export default function ShippingShipper() {
  const { isLoading, shipping, totalShipping, getShipping } = useShipping();
  const toast = useToast();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract the 'page' and 'limit' parameters
  const page = queryParams.get('page') || 1; // Default to 1 if not provided
  const limit = queryParams.get('limit') || 10; // Default to 10 if not provided
  
  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    getShipping(page, limit);
  }, [page]);

  return (
    <>
      {false ? (
        <Loader opacity={0.2}></Loader>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="34px">
            Shipping
          </Text>
          <Box pt={{ base: '130px', md: '30px', xl: '30px' }}>
            <Flex px="25px" mb="20px" align="center">
              <Flex align="center">
                <SearchBar me="10px" borderRadius="30px" />
                <Filter />
              </Flex>
              <Spacer />
              <Button
                onClick={handleRefresh}
                bgColor="purple.300"
                color="white"
                mr={2}
              >
                Refresh
              </Button>
            </Flex>
            <ShippingTable
              columnsData={columnsDataDevelopment}
              tableData={shipping}
            />
            <Spacer mt={4} />
            <Pagination totalData={totalShipping} rowsPerPage={limit} />
          </Box>
        </>
      )}
    </>
  );
}
