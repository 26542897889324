import axios from 'axios';
import { useState } from 'react';
import api from 'utils/api';
const url = process.env.REACT_APP_API_URL;

export default function useDealing() {
  const [dealing, setDealing] = useState([]);
  const [firstDealingInformation, setFirstDealingInformation] = useState([]);
  const [firstDealingVerification, setFirstDealingVerification] = useState([]);
  const [secondDealingInformation, setSecondDealingInformation] = useState([]);
  const [secondDealingVerification, setSecondDealingVerification] = useState([]);
  const [secondDealingProcessForm, setSecondDealingProcessForm] = useState({
    payment_method: null,
    dealing_rule: null,
  });
  const [totalDealing, setTotalDealing] = useState(0);
  const [kolCategories, setKolCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const feUrl = process.env.REACT_APP_FE_URL;

  async function getDealing(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/dealing/data/negotiate/all?page=${page}&limit=${limit}`,
      );

      setTotalDealing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id;
        const approval_date = val.updated_at?.split('T')[0] || '';
        const username = val.kol?.username ?? '';
        const platform = val.kol?.platform?.name ?? '';
        const rate_card = val.cost?.rate_card ?? '';
        const add_cost = val.cost?.add_cost ?? '';
        const kol_category = val.kol?.kol_category?.name ?? '';
        const note = val.note ?? '';
        const dealing_type = val.type ?? '';

        const object = {
          id,
          approval_date,
          username,
          platform,
          rate_card,
          add_cost,
          kol_category,
          note,
          dealing_type,
        };
        value.push(object);
      });

      setDealing(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function getFirstDealingInformation(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/dealing/data/first-form/all?page=${page}&limit=${limit}`,
      );

      setTotalDealing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const kol_id = val.kol?.id ?? '';
        const deal_date = val.kol_detail_form?.created_at?.split('T')[0] || '';
        const socmed_id = val.kol?.username ?? '';
        const link = val.kol?.link ?? '';
        const platform = val.kol?.platform?.name ?? '';
        const url = val.kol_detail_form?.uid ?? '';
        const dealing_type = val.type;

        const object = {
          deal_date,
          kol_id,
          username: {
            socmed_id,
            link,
          },
          platform,
          url: `${feUrl}/kol/form/1/${url}`,
          dealing_type,
        };
        value.push(object);
        console.log(object);
      });

      setFirstDealingInformation(value);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function getFirstDealingVerification(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/dealing/verification/data/first-form/all?page=${page}&limit=${limit}`,
      );

      setTotalDealing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id;
        const kol_id = val.kol?.id ?? '';
        const submitted_at = val.kol_detail_form?.first_form_status?.submitted_at?.split('T')[0] || '';
        const socmed_id = val.kol?.username ?? '';
        const link = val.kol?.link ?? '';
        const platform = val.kol?.platform?.name ?? '';
        const full_name = val.kol?.kol_detail?.[0]?.full_name ?? '';
        const nik = val.kol?.kol_detail?.[0]?.nik ?? '';
        const whatsapp_number = val.kol?.kol_detail?.[0]?.whatsapp_number ?? '';
        const bank_name = val.kol?.kol_detail?.[0]?.bank?.name ?? '';
        const bank_account_name = val.kol?.kol_detail?.[0]?.bank_account_name ?? '';
        const bank_account_number = val.kol?.kol_detail?.[0]?.bank_account_number ?? '';
        const home_address = val.kol?.kol_detail?.[0]?.home_address ?? '';
        const born_at = val.kol?.kol_detail?.[0]?.born_at ?? '';
        const birth_date = val.kol?.kol_detail?.[0]?.birth_date ?? '';
        const total_contract_price = val.cost?.total_amount ?? '';
        const total_contract_slot = val.cost?.total_slot ?? '';
        const quotation_file = val.contract?.contract_file?.[0]?.file_url ?? '-';

        const object = {
          id,
          kol_id,
          submitted_at,
          username: {
            socmed_id,
            link,
          },
          platform,
          full_name,
          nik,
          whatsapp_number,
          bank_name,
          bank_account_name,
          bank_account_number,
          home_address,
          born_at,
          birth_date,
          total_contract_price,
          total_contract_slot,
          quotation_file,
        };
        value.push(object);
      });

      setFirstDealingVerification(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function getSecondDealingInformation(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/dealing/data/second-form/all?page=${page}&limit=${limit}`,
      );

      setTotalDealing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const verified_at =
          val.kol_detail_form?.first_form_status?.submitted_at?.split('T')[0] ||
          '';
        const kol_id = val.kol?.id ?? '';
        const socmed_id = val.kol?.username ?? '';
        const link = val.kol?.link ?? '';
        const platform = val.kol?.platform?.name ?? '';
        const link_to_share = val.kol_detail_form?.uid ?? '';
        const dealing_type = val.type;
        const contract_document_type =
          val.contract.contract_file?.[0]?.contract_document_type;
        const document = val.contract?.contract_file?.[0]?.file_url;

        console.log(document);
        const object = {
          verified_at,
          kol_id,
          username: {
            socmed_id,
            link,
          },
          platform,
          url: `${feUrl}/kol/form/2/${link_to_share}`,
          dealing_type,
          contract_document_type,
          document,
        };
        value.push(object);
      });

      setSecondDealingInformation(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function getSecondDealingVerification(page, limit) {
    let value = [];
    try {
      let { data } = await api.get(
        `/dealing/verification/data/second-form/all?page=${page}&limit=${limit}`,
      );

      setTotalDealing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id;
        const kol_id = val.kol?.id ?? '';
        const submitted_at = val.kol_detail_form?.first_form_status?.submitted_at?.split('T')[0] || '';
        const socmed_id = val.kol?.username ?? '';
        const link = val.kol?.link ?? '';
        const full_name = val.kol?.kol_detail?.[0]?.full_name ?? '';
        const platform = val.kol?.platform?.name ?? '';
        const payment_method = val.contract?.contract_detail?.payment_method?.name ?? '';
        const contract_dealing_rule = val.contract?.contract_detail?.dealing_rule?.name ?? '';
        const total_contract_price = val.cost?.total_amount ?? '';
        const nik = val.kol?.kol_detail?.[0]?.nik ?? '';
        const identity_type = val.kol?.identity_card?.[0]?.file_type_configuration_id === 4 ? 'KTP' : 'NPWP';
        const identity_doc = val.kol?.identity_card?.[0]?.file_url ?? '';
        const gross_nett = val.contract?.contract_taxes?.tax_calculation_basis ?? '';
        const tax_type = val.contract?.contract_taxes?.tax_type ?? '';
        const tax_percentage = val.contract?.contract_taxes?.tax_percentage ?? '';
        const total_contract_price_after_tax = val.cost?.total_amount_after_tax ?? '';
        const signed_contract_document = val.contract?.contract_file?.[0]?.file_url;

        const object = {
          id,
          kol_id,
          submitted_at,
          username: {
            socmed_id,
            link,
          },
          full_name,
          platform,
          payment_method,
          contract_dealing_rule,
          total_contract_price,
          nik,
          identity_type,
          identity_doc,
          gross_nett,
          tax_type,
          tax_percentage,
          total_contract_price_after_tax,
          signed_contract_document,
        };
        value.push(object);
      });

      setFirstDealingVerification(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function getSecondDealingProcessForm() {
    try {
      let { data } = await api.get(
        `/dealing/verification/second-form/process-payment-data`,
      );

      data = data.data;

      const object = {
        payment_method: data.payment_method,
        dealing_rule: data.dealing_rule,
      };

      setSecondDealingProcessForm(object);
      console.log(data);
    } catch (error) {
      console.log(error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const postFinalDealing = async (body, index) => {
    try {
      let { data } = await api.post(`/dealing/${index}`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const postFirstDealingVerification = async (body, id) => {
    try {
      // add withCredentials: true to avoid sent payload empty
      const { data } = await axios.post(
        `${url}/dealing/verification/first-form/${id}`,
        body,
        {
          withCredentials: true,
        },
      );
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const postSecondDealingVerification = async (body, id) => {
    try {
      // add withCredentials: true to avoid sent payload empty
      const { data } = await axios.post(
        `${url}/dealing/verification/second-form/${id}`,
        body,
        {
          withCredentials: true,
        },
      );
      console.log(data);
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    dealing,
    firstDealingInformation,
    firstDealingVerification,
    secondDealingInformation,
    secondDealingVerification,
    secondDealingProcessForm,
    isLoading,
    error,
    kolCategories,
    totalDealing,
    getDealing,
    getFirstDealingInformation,
    getFirstDealingVerification,
    getSecondDealingInformation,
    getSecondDealingVerification,
    getSecondDealingProcessForm,
    postFinalDealing,
    postFirstDealingVerification,
    postSecondDealingVerification,
  };
}
