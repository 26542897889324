import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import { useAuthContext } from 'contexts/AuthContext';
import ImageResize from 'quill-image-resize-module-react';

import React, { useEffect } from 'react';
import { useCallback, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import useIdeation from 'hooks/useIdeation';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import CustomInputForm from 'components/fields/TextInputForm';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';

Quill.register('modules/imageResize', ImageResize);

export default function IdeationEditForm() {
  const { id } = useParams();
  const { userRole } = useAuthContext();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    ideationById,
    category,
    product,
    getIdeationById,
    getBriefFormUtils,
    postImageIdeation,
    editIdeation,
    deleteIdeation,
  } = useIdeation();
  const reactQuillRef = useRef(null);
  const animatedComponents = makeAnimated();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ideationData = await getIdeationById(id, userRole);
        const utilsData = await getBriefFormUtils();
        setInitialVariables(ideationData, utilsData, 'category');
        setInitialVariables(ideationData, utilsData, 'product');
        setCurrentStatus(ideationData.status);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const setInitialVariables = (ideationData, utilsData, type) => {
    const mapData = (data) =>
      data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

    const setSelectedFunction =
      type === 'category' ? setSelectedCategories : setSelectedProducts;
    const setAvailableFunction =
      type === 'category' ? setAvailableCategories : setAvailableProducts;

    const initialSelected = mapData(ideationData[type]);
    setSelectedFunction(initialSelected);

    const initialAvailable = utilsData[type]
      .filter(
        (item) =>
          !ideationData[type].some((userItem) => userItem.id === item.id),
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));

    setAvailableFunction(initialAvailable);
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);

    const updatedAvailable = category
      .filter(
        (item) =>
          !selectedOptions.some((selected) => selected.value === item.id),
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));

    setAvailableCategories(updatedAvailable);
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);

    const updatedAvailable = product
      .filter(
        (item) =>
          !selectedOptions.some((selected) => selected.value === item.id),
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));

    setAvailableProducts(updatedAvailable);
  };

  // Custom function to check for the Quill placeholder value
  const isQuillEmpty = (value) => {
    const trimmedValue = value.replace(/<(.|\n)*?>/g, '').trim(); // Remove HTML tags and trim whitespace
    return trimmedValue === '';
  };

  const validationSchema = Yup.object().shape({
    category:
      selectedCategories.length === 0 &&
      Yup.array()
        .min(1, 'At least one category is required')
        .required('Category is required'),
    product:
      selectedProducts.length === 0 &&
      Yup.array()
        .min(1, 'At least one product is required')
        .required('Product is required'),
    detail: Yup.string()
      .required('Detail is required')
      .test(
        'is-quill-empty',
        'Detail is required',
        (value) => !isQuillEmpty(value),
      ),
  });

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const formData = new FormData();
        formData.append('brief_file', input.files[0]);

        const url = await postImageIdeation(5, formData);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditor().getSelection();
          range && quill.getEditor().insertEmbed(range.index, 'image', url);
        }
      }
    };
  }, []);

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title,
      hook: values.hook,
      detail: values.detail,
      attached_categories: selectedCategories.map((categoryId) => ({
        brief_category_id: categoryId.value,
      })),
      attached_products: selectedProducts.map((productId) => ({
        product_id: productId.value,
      })),
      note: null,
    };

    console.log(payload);
    try {
      await editIdeation(id, payload);
      toast({
        title: 'Form updated successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      navigate('/ideation/kol');
    } catch (error) {
      toast({
        title: 'Cannot update form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteIdeation(id);
      toast({
        title: 'Form deleted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      navigate('/ideation/kol');
    } catch (error) {
      toast({
        title: 'Cannot delete form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const initialValues = {
    title: ideationById?.title || '',
    hook: ideationById?.hook || '',
    detail: ideationById?.detail || '',
    category: selectedCategories,
    product: selectedProducts,
    note: ideationById?.node || '',
  };

  return ideationById === null ? (
    <Loader opacity={1}></Loader>
  ) : (
    <>
      <Text fontWeight="bold" fontSize="34px" mb={5}>
        Ideation
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
        }) => (
          <>
            <Form>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacingX={5}
                spacingY={5}
                mb="10px"
              >
                <GridItem>
                  <FormControl mb={4}>
                    <CustomInputForm
                      values={values}
                      label={'Title'}
                      id={'title'}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl
                    mb={4}
                    isRequired={true}
                    isInvalid={errors.category && touched.category}
                  >
                    <FormLabel>Category</FormLabel>
                    <Field name="category">
                      {({ field }) => (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={availableCategories}
                          value={selectedCategories}
                          onChange={(selectedOptions) => {
                            handleCategoryChange(selectedOptions);
                            setFieldValue(
                              field.name,
                              selectedOptions.map((option) => option.value),
                            );
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: 'transparent',
                              borderColor: '#a6a7a7',
                            }),
                          }}
                        />
                      )}
                    </Field>
                    {errors.category && touched.category ? (
                      <Text color="red.500" mt={2}>
                        {errors.category}
                      </Text>
                    ) : null}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <SimpleGrid
                columns={{ base: 1, md: 1 }}
                spacingX={5}
                spacingY={5}
                mb="10px"
              >
                <GridItem>
                  <FormControl mb={4}>
                    <CustomInputForm
                      values={values}
                      label={'Hook'}
                      id={'hook'}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 1, md: 4 }}
                spacingX={5}
                spacingY={5}
                mb="10px"
              >
                {/* Row 3 */}
                <GridItem colSpan={4}>
                  <FormControl
                    mb={4}
                    isRequired={true}
                    isInvalid={errors.product && touched.product}
                  >
                    <FormLabel>Product</FormLabel>
                    <Field name="product">
                      {({ field }) => (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={availableProducts}
                          value={selectedProducts}
                          onChange={(selectedOptions) => {
                            handleProductChange(selectedOptions);
                            setFieldValue(
                              field.name,
                              selectedOptions.map((option) => option.value),
                            );
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: 'transparent',
                              borderColor: '#a6a7a7',
                            }),
                          }}
                        />
                      )}
                    </Field>
                    {errors.product && touched.product ? (
                      <Text color="red.500" mt={2}>
                        {errors.product}
                      </Text>
                    ) : null}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              {currentStatus != null && (
                <SimpleGrid
                  columns={{ base: 1, md: 5 }}
                  spacingX={5}
                  spacingY={5}
                  mb="10px"
                >
                  <GridItem colSpan={5}>
                    <FormControl
                      mb={4}
                      isRequired={true}
                      isInvalid={errors.detail && touched.detail}
                    >
                      <FormLabel>Detail</FormLabel>
                      <ReactQuill
                        readOnly={currentStatus === 'approved'}
                        ref={reactQuillRef}
                        theme="snow"
                        placeholder="Start writing..."
                        modules={{
                          toolbar:
                            currentStatus === 'approved'
                              ? false
                              : {
                                  container: [
                                    [
                                      { header: '1' },
                                      { header: '2' },
                                      { font: [] },
                                    ],
                                    [{ size: [] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote',
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' },
                                    ],
                                    ['link', 'image', 'video'],
                                    ['code-block'],
                                    ['clean'],
                                  ],
                                  handlers: {
                                    image: imageHandler,
                                  },
                                },
                          clipboard: {
                            matchVisual: false,
                          },
                          imageResize: {
                            parchment: Quill.import('parchment'),
                            modules: ['Resize', 'DisplaySize'],
                          },
                        }}
                        formats={[
                          'header',
                          'font',
                          'size',
                          'bold',
                          'italic',
                          'underline',
                          'strike',
                          'blockquote',
                          'list',
                          'bullet',
                          'indent',
                          'link',
                          'image',
                          'code-block',
                          'width',
                        ]}
                        value={values.detail} // Bind the value to Formik's state
                        onChange={(detail) => setFieldValue('detail', detail)} // Update Formik's state on change
                      />
                      {errors.detail && touched.detail ? (
                        <Text color="red.500" mt={2}>
                          {errors.detail}
                        </Text>
                      ) : null}
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
              )}

              {currentStatus === 'need revision' && (
                <SimpleGrid
                  columns={{ base: 1, md: 5 }}
                  spacingX={5}
                  spacingY={5}
                  mb="20px"
                >
                  <GridItem colSpan={5}>
                    <FormControl mb={4}>
                      <FormLabel>Revision Note</FormLabel>
                      <Textarea value={ideationById.note} readOnly />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
              )}

              <Text color="black" mt={2}>
                Created by: {ideationById.created_by} at{' '}
                {ideationById.created_at}
              </Text>
              <Text color="black" mt={2} textTransform="capitalize">
                Status: {ideationById.status}
              </Text>

              {currentStatus === 'approved' ? (
                <Flex justify="flex-end" mt="12px">
                  <Button
                    onClick={() => navigate('/ideation/kol')}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="10%"
                    h="35"
                    mt="12px"
                    mx="5px"
                    disabled={isSubmitting}
                    bg="grey.300"
                    color="purple.600"
                    border="1px"
                    borderColor="purple.600"
                    _hover={{ bg: 'gray.300' }}
                  >
                    Close
                  </Button>
                </Flex>
              ) : (
                <Flex justify="flex-end" mt="12px">
                  <Button
                    onClick={() => navigate('/ideation/kol')}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="10%"
                    h="35"
                    mt="12px"
                    disabled={isSubmitting}
                    bg="grey.300"
                    color="purple.600"
                    _hover={{ bg: 'gray.300' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="10%"
                    h="35"
                    mt="12px"
                    mx="5px"
                    disabled={isSubmitting}
                    bg="red.500"
                    color="white"
                    _hover={{ bg: 'red.700' }}
                  >
                    Delete
                  </Button>
                  <Button
                    type="submit"
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="10%"
                    h="35"
                    mt="12px"
                    _hover={{ bg: 'purple.700' }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Flex>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
