import axios from 'axios';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

let onRefreshing = false

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 400 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!onRefreshing) {
        onRefreshing = true;

        try {
          await api.get('/user/refresh-token');
          onRefreshing = false;
          return api(originalRequest);
        } catch (refreshError) {
          onRefreshing = false;
          console.error('Token refresh failed:', refreshError);
          return <Navigate to="/login" />;
        }
      }
    }
    return Promise.reject(error);
  },
);
export default api;
