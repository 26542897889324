import { useState } from 'react';
import api from 'utils/api';

export default function useIdeation() {
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [ideation, setIdeation] = useState([]);
  const [ideationById, setIdeationById] = useState(null);
  const [isError, setError] = useState('');
  const [totalIdeation, setTotalIdeation] = useState(0);

  const getIdeation = async (page, limit) => {
    let value = [];
    try {
      let { data } = await api.get(
        `/brief/data/all?page=${page}&limit=${limit}`,
      );

      setTotalIdeation(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id ?? '';
        const created_at = val.created_at?.split('T')[0] || '';
        const updated_at = val.updated_at?.split('T')[0] || '';
        const approved_at = val.brief_action?.[0]?.created_at.split('T')[0] || '';
        const title = val.title ?? '';
        const category = val.brief_attached_category ?? '';
        const product = val.brief_attached_product ?? '';
        const hook = val.hook ?? '';
        const detail = val.detail ?? '';
        const is_approved = val.is_approved ?? '';
        const created_by = val.created_by.name ?? '';
        const status = val.brief_action?.[0]?.action_status ?? '';

        const object = {
          id,
          created_at,
          updated_at,
          approved_at,
          title,
          category,
          product,
          hook,
          detail,
          is_approved,
          status,
          created_by,
        };
        value.push(object);
      });

      setIdeation(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getIdeationById = async (index, userRole) => {
    let value;

    console.log(userRole)
    try {
      let { data } = await api.get(`/brief/data/${index}`);

      if (userRole === 'KOL Specialist') {
        const data_kol = data.data;
        value = {
          created_at: data_kol.created_at?.split('T')[0] || '',
          updated_at: data_kol.updated_at?.split('T')[0] || '',
          title: data_kol.title ?? '',
          category: data_kol.brief_attached_category ?? '',
          product: data_kol.brief_attached_product ?? '',
          hook: data_kol.hook ?? '',
          detail: data_kol.detail ?? '',
          is_approved: data_kol.is_approved ?? '',
          created_by: data_kol.created_by?.name ?? '',
          status: data_kol.brief_action?.[0]?.action_status ?? '',
          note: data_kol.brief_action?.[0]?.brief_action_note?.note ?? '',
        };
      } else if (userRole === 'Manager') {
        const data_manager = data.data.data;
        value = {
          created_at: data_manager.created_at?.split('T')[0] || '',
          updated_at: data_manager.updated_at?.split('T')[0] || '',
          title: data_manager.title ?? '',
          category: data_manager.brief_attached_category ?? '',
          product: data_manager.brief_attached_product ?? '',
          hook: data_manager.hook ?? '',
          detail: data_manager.detail ?? '',
          is_approved: data_manager.is_approved ?? '',
          created_by: data_manager.created_by?.name ?? '',
          status: data_manager.brief_action?.[0]?.action_status ?? '',
          note:
            data.data.latest_revision_data?.[0]?.brief_action_note?.note ?? '',
        };
      }

      console.log(value);
      setIdeationById(value);
      return value;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const postIdeation = async(body) => {
    try {
      const { data } = await api.post(`/brief`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const postIdeationRevision = async(index, body) => {
    try {
      const { data } = await api.post(`/brief/${index}`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const editIdeation = async (index, body) => {
    try {
      const { data } = await api.patch(`/brief/${index}`, body);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteIdeation = async (index) => {
    try {
      const { data } = await api.delete(`/brief/${index}`);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const postImageIdeation = async (index, body) => {
    try {
      const { data } = await api.post(`/brief/${index}/upload-images`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data.brief_file[0].location;
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getBriefFormUtils = async () => {
    try {
      const [dataCategory, dataProduct] = await Promise.all([
        api.get('/brief/category/data/all'),
        api.get('/product/data/all'),
      ]);

      const category = dataCategory.data.data;
      const product = dataProduct.data.data;

      setCategory(category);
      setProduct(product);

      return {
        category,
        product,
      };
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    ideation,
    ideationById,
    category,
    product,
    totalIdeation,
    isLoading,
    isError,
    getIdeation,
    getIdeationById,
    postIdeation,
    postIdeationRevision,
    postImageIdeation,
    deleteIdeation,
    editIdeation,
    getBriefFormUtils,
  };
}
