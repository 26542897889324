export const columnsDataDevelopment = [
  {
    Header: 'DEAL DATE',
    accessor: 'deal_date',
  },
  {
    Header: 'KOL ID',
    accessor: 'kol_id',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  {
    Header: 'URL',
    accessor: 'url',
  },
  {
    Header: 'DEALING TYPE',
    accessor: 'dealing_type',
  },
];
