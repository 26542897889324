export const columnsDataDevelopment = [
  {
    Header: 'BRIEF ID',
    accessor: 'id',
  },
  {
    Header: 'CREATED AT',
    accessor: 'created_at',
  },
  {
    Header: 'UPDATED AT',
    accessor: 'updated_at',
  },
  {
    Header: 'APPROVED AT',
    accessor: 'approved_at',
  },
  {
    Header: 'CREATED BY',
    accessor: 'created_by',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  {
    Header: 'HOOK',
    accessor: 'hook',
  },
  {
    Header: 'BRIEF CATEGORIES',
    accessor: 'category',
  },
  {
    Header: 'ATTACHED PRODUCTS',
    accessor: 'product',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
