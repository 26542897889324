import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Icon,
  VStack,
  Text,
  Image
} from '@chakra-ui/react';
import {
  MdKeyboardArrowRight,
  MdBarChart,
  MdLibraryAddCheck,
  MdLocalShipping,
  MdPerson,
  MdCalendarMonth,
  MdInsertChartOutlined,
  MdLightbulbOutline,
  MdHandshake,
} from 'react-icons/md';
import {
  FaUserGraduate,
  FaHome,
  FaSignOutAlt,
  FaRegHandshake,
  FaInstagram,
  FaTiktok,
} from 'react-icons/fa';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSidebarContext } from 'contexts/SidebarContexts';
import { LuDatabase } from 'react-icons/lu';
import { useAuthContext } from 'contexts/AuthContext';

const Sidebar = ({ route }) => {
  console.log('route', route);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpened, closeSidebar } = useSidebarContext();
  const [openSections, setOpenSections] = useState({ flow: true });
  const [openSubcategory, setOpenSubcategory] = useState({ flow: true });
  const { postLogout } = useAuthContext();

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleSubcategory = (section) => {
    setOpenSubcategory((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const icon = {
    listing: MdBarChart,
    negotiate: MdHandshake,
    'first dealing' : MdLibraryAddCheck,
    'second dealing' : MdLibraryAddCheck,
    shipping: MdLocalShipping,
    profile: MdPerson,
    timeline: MdCalendarMonth,
    instagram: FaInstagram,
    tiktok: FaTiktok,
    summary: MdInsertChartOutlined,
    ideation: MdLightbulbOutline,
    database: LuDatabase,
  };

  return (
    <Flex
      as="aside"
      w={{ base: 'full', md: 40, lg: 60 }}
      h="calc(100vh - 2rem)"
      bgColor="purple.500"
      borderRadius={20}
      alignItems="strecth"
      flexDir="column"
      flexShrink={0}
      rowGap={4}
      ml={4}
      my={4}
      pos="fixed" // This ensures the sidebar is fixed in the viewport
      top={0} // Anchors the sidebar to the top of the screen
      left={0} // Anchors the sidebar to the left of the screen
      zIndex={20} // Ensures the sidebar is above other elements
    >
      <Heading
        color="white"
        fontSize="2xl"
        fontWeight="semibold"
        textAlign="center"
        pt={12}
      >
        FACETOLOGY
      </Heading>
      <VStack my={6} alignItems="stretch">
        {Object.entries(route).map(([section, subsection], id) => (
          <Box key={id} width="100%">
            <Button
              _hover={{ backgroundColor: 'purple.500' }}
              bgColor="purple.500"
              color="white"
              width="100%"
              justifyContent="space-between"
              onClick={() =>
                Array.isArray(subsection)
                  ? toggleSection(section)
                  : navigate(`${subsection.path_name}`)
              }
              fontSize="14px"
              rightIcon={
                openSections[section] ? (
                  <Icon as={MdKeyboardArrowRight} />
                ) : (
                  <Icon as={MdKeyboardArrowRight} />
                )
              }
            >
              {section.toUpperCase()}
            </Button>
            {console.log(subsection)}
            {Array.isArray(subsection) ? (
              <Collapse in={openSections[section]}>
                <VStack align="stretch" pl="2" mt="2" spacing="2">
                  {subsection.map((link, idx) => (
                    <>
                      {console.log('entity_icon', link.pages?.[0]?.entity_icon || '')}

                      <Flex
                        key={idx}
                        rounded="md"
                        color="white"
                        py={2}
                        px={2}
                        alignItems="center"
                        fontSize="md"
                        _hover={{ shadow: 'md' }}
                        cursor="pointer"
                        as={Link}
                        to={
                          Array.isArray(link) && link.entity_subcategory == null
                            ? link.path_name
                            : link.pages[0].path_name
                        }
                        role="group"
                        onClick={closeSidebar}
                      >
                        <Box
                          bgColor={
                            link.path_name === pathname
                              ? 'white'
                              : 'transparent'
                          }
                          w={1}
                          h="full"
                          mr={4}
                          _groupHover={{ backgroundColor: 'white' }}
                        />
                        {/* <Icon as={icon[link.page]} mr={6} /> */}
                        <Image
                          boxSize='22px'
                          objectFit='contain'
                          src={
                            link.pages?.[0]?.entity_icon || ''
                          }
                          alt='icon'
                          mr={4}
                        />
                        <Box>
                          <Text textTransform="capitalize">
                            {link.entity_subcategory
                              ? link.entity_subcategory
                              : link.pages[0].page}
                          </Text>
                        </Box>
                      </Flex>
                      {/* Custom */}
                      {link.entity_subcategory && (
                        <Box>
                          {link.pages.map((item, idx) => (
                            <Text
                              ml="68px"
                              mb={2}
                              textTransform="capitalize"
                              color="white"
                              onClick={() => navigate(`${item.path_name}`)}
                              cursor="pointer"
                            >
                              {item.page}
                            </Text>
                          ))}
                        </Box>
                      )}
                    </>
                  ))}
                </VStack>
              </Collapse>
            ) : (
              <></>
            )}
          </Box>
        ))}
      </VStack>
      <Button
        mt="auto"
        mb={8}
        mx={4}
        onClick={postLogout}
        minH={10}
        leftIcon={<Icon as={FaSignOutAlt} />}
      >
        Logout
      </Button>
    </Flex>
  );
};

export default Sidebar;
