export const columnsDataDevelopment = [
  {
    Header: "LISTING_DATE",
    accessor: "listing_date",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "PLATFORM",
    accessor: "platform",
  },
  {
    Header: "KOL_CATEGORY",
    accessor: "kol_category",
  },
  {
    Header: "NOTE",
    accessor: "note",
  },
  {
    Header: "CPM",
    accessor: "cpm",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "ISSUED_BY",
    accessor: "issued_by",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];


