import {
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  IconButton,
  Box,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { GoUpload } from 'react-icons/go';
import { BsFilePdf } from 'react-icons/bs';
import { TiDeleteOutline } from 'react-icons/ti';
import { IoArrowBack } from 'react-icons/io5';
import React, { useMemo, useRef, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useDropzone } from 'react-dropzone';
import useDealing from 'hooks/useDealing';

export default function DealingTable(props) {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { postFirstDealingVerification } = useDealing();
  const toast = useToast();

  const [file, setFile] = useState(null);
  const [chosenFile, setChooseFile] = useState(null);
  const [selectedRowId, setRowId] = useState(null);

  const handleRemoveFile = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    noClick: true, // Prevents the default click behavior since we're using a custom button
    noKeyboard: true, // Disables opening the dialog on Enter/Space keys
  });

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const iconColor = useColorModeValue('blue.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  function handleRowEdit(id) {
    onOpen();
    setRowId(id);
  }

  const handleSubmit = async () => {
    const id = selectedRowId;
    const formData = new FormData();

    if (chosenFile === 'SPK') {
      formData.append('spk_unsigned', file);
    } else if (chosenFile === 'MoU') {
      formData.append('mou_unsigned', file);
    }

    try {
      console.table([...formData]);
      console.log(id);
      await postFirstDealingVerification(formData, id);

      onClose();
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'SUBMITTED AT') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'KOL ID') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'USERNAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value.link} target="_blank">
                            {cell.value.socmed_id}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'PLATFORM') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          <a href={cell.value} target="_blank">
                            {cell.value}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'FULL NAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          <a href={cell.value} target="_blank">
                            {cell.value}
                          </a>
                        </Text>
                      );
                    } else if (cell.column.Header === 'NIK') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'WHATSAPP NUMBER') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'BANK NAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'BANK ACCOUNT NAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'BANK ACCOUNT NUMBER') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'BORN AT') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'BIRTH DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'HOME ADDRESS') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'TOTAL CONTRACT PRICE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          Rp{cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'TOTAL CONTRACT SLOT') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'INVOICE FILE') {
                      data =
                        cell.value !== '-' ? (
                          <Text
                            color="purple.500"
                            cursor="pointer"
                            textDecoration="underline"
                          >
                            <a href={cell.value} target="_blank">
                              show
                            </a>
                          </Text>
                        ) : (
                          cell.value
                        );
                    } else if (cell.column.Header === 'ACTION') {
                      data = (
                        <Text
                          color="purple.500"
                          onClick={() => handleRowEdit(row.original.id)}
                          cursor="pointer"
                          textDecoration="underline"
                        >
                          upload file
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Modal for file upload */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <IconButton
                icon={<IoArrowBack />}
                onClick={() => setFile(null)}
                variant="ghost"
                aria-label="Go back"
              />
              Upload Files {chosenFile}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!chosenFile && (
                <Box
                  cursor="pointer"
                  border="2px"
                  borderColor="gray.200"
                  borderRadius="md"
                  padding="4"
                  textAlign="center"
                  mb={4}
                >
                  <Text>Choose SPK or MoU Document</Text>
                  <Button
                    mt={4}
                    onClick={() => setChooseFile('SPK')}
                    bgColor="purple.500"
                    color="white"
                    mr={2}
                  >
                    SPK
                  </Button>
                  <Button
                    mt={4}
                    onClick={() => setChooseFile('MoU')}
                    bgColor="purple.500"
                    color="white"
                  >
                    MoU
                  </Button>
                </Box>
              )}

              {chosenFile && (
                <Box
                  {...getRootProps()}
                  border="2px"
                  borderColor="gray.200"
                  borderRadius="md"
                  padding="4"
                  textAlign="center"
                  mb={!file ? 4 : 0}
                >
                  <Input {...getInputProps()} />
                  <Text>Drag & drop or click to choose files</Text>
                  <Button
                    leftIcon={<GoUpload />}
                    mt={4}
                    variant="outline"
                    borderColor="purple.500"
                    color="purple.500"
                    onClick={open} // This opens the file dialog
                  >
                    Select File
                  </Button>
                </Box>
              )}

              {file && (
                <>
                  <Box
                    border="2px"
                    borderColor="gray.200"
                    borderRadius="md"
                    textAlign="center"
                    mt={3}
                    p={2}
                  >
                    <Flex align="center" justify="space-between">
                      <Flex align="center">
                        <Icon as={BsFilePdf} color="red.500" boxSize="20px" />
                        <Text ml={2}>{file.name}</Text>
                      </Flex>
                      <IconButton
                        icon={<TiDeleteOutline height="5px" width="5px" />}
                        onClick={handleRemoveFile}
                        aria-label="Remove file"
                        backgroundColor="transparent"
                        color="gray.500"
                        width="30px"
                        height="30px"
                        _hover={{
                          backgroundColor: 'gray.100', // Optional hover effect
                          color: 'gray.600',
                        }}
                      />
                    </Flex>
                  </Box>
                  <Button
                    bgColor="purple.500"
                    color="white"
                    onClick={handleSubmit}
                    w="100%"
                    mt={4}
                    mb={4}
                  >
                    Upload
                  </Button>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Card>
    </>
  );
}
