import { useState } from 'react';
import api from 'utils/api';

export default function useKol() {
  const [kolData, setKol] = useState([]);
  const [kolDetailContract, setKolDetailContract] = useState({
    contract_id: null,
    contract_component: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [kolDetailData, setKolDetail] = useState({
    full_name: '',
    home_address: '',
    whatsapp_number: '',
  });

  const getKolByPlatform = async (platform) => {
    try {
      let { data } = await api.get(`/kol/data/all?platform=${platform}`);
      setKol(data.data);
      console.log(data.data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getKolDetail = async (index) => {
    try {
      let { data } = await api.get(`/kol/${index}`);
      data = data.data.kol_detail[0];

      const kol_data = {
        full_name: data?.full_name ?? '',
        home_address: data?.home_address ?? '',
        whatsapp_number: data?.whatsapp_number ?? '',
      };

      setKolDetail(kol_data);
      console.log(kol_data);
      return kol_data;
    } catch (error) {
      console.log(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  const getKolDetailContract = async (id) => {
    try {
      console.log('id', id);
      let { data } = await api.get(`/kol/${id}/contract-component`);
      console.log('data', data);

      data = data.data?.contract[0] ?? null;

      console.log('data', data);

      if (data != null) {
        const kol_detail_contract = {
          contract_id: data.id,
          contract_component: data.contract_component,
        };
        setKolDetailContract(kol_detail_contract);
      } else {
        setKolDetailContract(null);
      }

      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  return {
    kolData,
    kolDetailData,
    kolDetailContract,
    error,
    isLoading,
    getKolByPlatform,
    getKolDetail,
    getKolDetailContract,
  };
}
