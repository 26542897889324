import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import useListing from 'hooks/useListing';
import * as Yup from 'yup';

const readOnlyColor = {
  bg: 'gray.100',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

const ApprovalStatus = Object.freeze({
  HOLD: 2,
  REJECTED: 3,
  APPROVED: 4,
});

const FormComponent = ({ cell, onClose, listingOptions }) => {
  console.log('listingOptions', listingOptions);
  const { postListingManager } = useListing();
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(0);
  const [note, setNote] = useState('');
  const toast = useToast();

  const initialValues = {
    username: cell.username,
    platform: cell.platform,
    kol_category: cell.kol_category,
    rate_card: cell.rate_card,
    rate_card_note: cell.rate_card.note,
    add_cost: cell.add_cost,
    note: cell.note,
  };

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    rate_card: Yup.array().of(
      Yup.object().shape({
        slot: Yup.string().when('nego_price', {
          is: (nego_price) => nego_price,
          then: (schema) => schema.required('Slot must be filled'),
          otherwise: (schema) => schema.notRequired(),
        }),
        nego_price: Yup.string().nullable(),
      }),
    ),
    add_cost: Yup.array().of(
      Yup.object().shape({
        add_cost_nego_price: Yup.string().nullable(),
      }),
    ),
  });

  const onSubmitApproval = async (values) => {
    const transformedData = {
      status: 'approved',
      dealing_type: 'new',
      business_type_id: businessTypeId,
      negotiated_cost: {
        rate_cards: values.rate_card
          .filter((cost) => (cost.nego_price && cost.slot) || cost.slot)
          .map((cost, index) => ({
            rate_card_id: cost.id,
            price: Number(cost.nego_price)
              ? Number(cost.nego_price)
              : Number(cost.slot) && cell.rate_card[index].price,
            slot: Number(cost.slot),
          })),
        add_costs: values.add_cost
          .filter((cost) => cost.add_cost_nego_price)
          .map((cost) => ({
            add_cost_id: cost.id,
            price: Number(cost.add_cost_nego_price),
          })),
      },
      note: values.approval_note || '',
    };

    try {
      console.log('Transformed Data', transformedData);
      await postListingManager(transformedData, cell.id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
      // window.location.reload();
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onHoldReject = async () => {
    const transformedData = {
      status: approvalStatus,
      dealing_type: 'new',
      action_note: note,
    };

    try {
      console.log('Transformed Data', transformedData);
      await postListingManager(transformedData, cell.id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
      window.location.reload();
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        {approvalStatus === 0 && (
          <>
            <FormLabel fontSize="lg" fontWeight="bold">
              Rate Card
            </FormLabel>

            {cell.rate_card.map((rateCard, index) => (
              <SimpleGrid
                columns={3}
                spacingX={5}
                spacingY={5}
                mb="20px"
                key={index}
              >
                <GridItem>
                  <FormLabel>Type</FormLabel>
                  <Input
                    value={rateCard.sow_type.name}
                    textTransform="capitalize"
                    readOnly
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl isReadOnly>
                    <FormLabel>Price</FormLabel>
                    <Input value={rateCard.price} />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isReadOnly>
                    <FormLabel>SOW</FormLabel>
                    <Input value={rateCard.sow} />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isReadOnly mb={4}>
                    <FormLabel>Note</FormLabel>
                    <Input value={rateCard.note} />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            ))}

            {cell.add_cost.length !== 0 && (
              <Flex justify="space-between">
                <Box>
                  <FormLabel fontSize="lg" fontWeight="bold">
                    Additional Cost
                  </FormLabel>
                </Box>
              </Flex>
            )}

            {cell.add_cost.map((cost, index) => (
              <SimpleGrid
                columns={3}
                spacingX={5}
                spacingY={5}
                mb="20px"
                key={index}
              >
                <GridItem>
                  <FormControl isReadOnly>
                    <FormLabel>Type</FormLabel>
                    <Input
                      textTransform="capitalize"
                      value={cost.sow_type.name}
                      readOnly
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl isReadOnly>
                    <FormLabel>Price</FormLabel>
                    <Input value={cost.price} />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isReadOnly mb={4}>
                    <FormLabel>Note</FormLabel>
                    <Input value={cost.note} />
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            ))}
          </>
        )}

        <FormControl mb={4}>
          <FormLabel>Approval Status</FormLabel>
          <Select
            placeholder="Select action"
            onChange={(e) => setApprovalStatus(Number(e.target.value))}
            value={approvalStatus}
            style={{ textTransform: 'capitalize' }}
          >
            {/* <option value="hold">Hold</option>
            <option value="rejected">Reject</option>
            <option value="approved">Approve</option> */}
            {listingOptions &&
              listingOptions.action_statuses.map((item, key) => (
                <option key={key} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </FormControl>
        {/* when user choose approval hold/rejected */}
        {(approvalStatus === ApprovalStatus.HOLD ||
          approvalStatus === ApprovalStatus.REJECTED) && (
          <>
            <SimpleGrid columns={3} mb="20px">
              <GridItem colSpan={3}>
                <FormControl mb={4}>
                  <FormLabel>Note</FormLabel>
                  <Input
                    placeholder="Enter a note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>

            <Box textAlign="right">
              <Button colorScheme="purple" onClick={() => onHoldReject()}>
                Submit
              </Button>
            </Box>
          </>
        )}
        {/* when user choose approved*/}
        {approvalStatus === ApprovalStatus.APPROVED && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitApproval}
            validateOnMount={true}
          >
            {({ values, handleChange, setFieldValue, errors }) => (
              <Form>
                <SimpleGrid columns={4} spacingX={5} spacingY={5} mb="20px">
                  {/* Row 1 */}
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>Username</FormLabel>
                      <Input
                        readOnly
                        value={values.username.socmed_id}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>Platform</FormLabel>
                      <Input
                        readOnly
                        value={values.platform}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>KOL Category</FormLabel>
                      <Input
                        readOnly
                        value={values.kol_category}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4} isRequired={true}>
                      <FormLabel>Business Type</FormLabel>
                      <Select
                        name="business_type"
                        placeholder="Select type"
                        onChange={(e) => {
                          handleChange(e); // Call handleChange with the event
                          setBusinessTypeId(Number(e.target.value)); // Set the index
                        }}
                        value={values.business_type}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {/* <option value="regular">Regular</option>
                        <option value="executive">Executive</option> */}
                        {listingOptions &&
                          listingOptions.business_types.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </SimpleGrid>

                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Rate Card
                    </FormLabel>
                  </Box>
                </Flex>
                <FieldArray name="rate_card">
                  {() => (
                    <>
                      {values.rate_card.map((rateCard, index) => (
                        <SimpleGrid
                          columns={4}
                          spacingX={5}
                          spacingY={5}
                          mb="20px"
                          key={index}
                        >
                          <GridItem>
                            <FormLabel>Type</FormLabel>
                            <Input
                              value={rateCard.sow_type.name}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Asli</FormLabel>
                            <Input
                              value={rateCard.price}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Harga Nego</FormLabel>
                              <Field
                                name={`rate_card[${index}].nego_price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl
                              isInvalid={errors.rate_card?.[index]?.slot}
                            >
                              <FormLabel>Slot</FormLabel>
                              <Field
                                name={`rate_card[${index}].slot`}
                                as={Input}
                                placeholder="Enter slot"
                              />
                              {/* <ErrorMessage name={`rate_card[${index}].slot`}>
                                {(msg) => (
                                  <Box color="red.500" fontSize="sm">
                                    {msg}
                                  </Box>
                                )}
                              </ErrorMessage> */}
                              {errors.rate_card?.[index]?.slot && (
                                <FormErrorMessage>
                                  {errors.rate_card[index].slot}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </GridItem>
                          {/* Row 2 */}
                          <GridItem colSpan={4}>
                            <FormControl mb={4}>
                              <FormLabel>Keterangan</FormLabel>
                              <Input
                                value={rateCard.note}
                                readOnly
                                _readOnly={readOnlyColor}
                              />
                            </FormControl>
                          </GridItem>
                        </SimpleGrid>
                      ))}
                    </>
                  )}
                </FieldArray>

                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Add Cost
                    </FormLabel>
                  </Box>
                </Flex>
                <FieldArray name="add_cost">
                  {() => (
                    <>
                      {values.add_cost.map((addCost, index) => (
                        <SimpleGrid
                          columns={3}
                          spacingX={5}
                          spacingY={5}
                          mb="20px"
                          key={index}
                        >
                          <GridItem>
                            <FormLabel>Type</FormLabel>
                            <Input
                              value={addCost.sow_type.name}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Asli</FormLabel>
                            <Flex>
                              <Input
                                value={addCost.price}
                                readOnly
                                _readOnly={readOnlyColor}
                              />
                            </Flex>
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Nego</FormLabel>
                            <Flex>
                              <Field
                                name={`add_cost[${index}].add_cost_nego_price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                            </Flex>
                          </GridItem>
                        </SimpleGrid>
                      ))}
                    </>
                  )}
                </FieldArray>

                <SimpleGrid columns={3} mb="20px">
                  <GridItem colSpan={3}>
                    <FormControl mb={4}>
                      <FormLabel fontSize="lg" fontWeight="bold">
                        Keterangan Kerjasama
                      </FormLabel>
                      <Input
                        value={cell.note}
                        readOnly
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
                <SimpleGrid columns={3} mb="20px">
                  <GridItem colSpan={3}>
                    <FormControl mb={4}>
                      <FormLabel fontSize="lg" fontWeight="bold">
                        Approval Note
                      </FormLabel>
                      <Field
                        name="approval_note"
                        as={Input}
                        placeholder="Enter notes"
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>

                <Box textAlign="right">
                  <Button colorScheme="purple" type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
