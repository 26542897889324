import React from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Textarea,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import useShipping from 'hooks/useShipping';
import CustomInputForm from 'components/fields/TextInputForm';

const readOnlyColor = {
  bg: 'transparant',
  color: 'gray.700',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

const FormComponent = ({ cell, expeditions, onClose }) => {
  const { postShippingExpedition } = useShipping();
  const toast = useToast();

  const initialValues = {
    tracking_number: '',
    expedition_type: '',
  };

  const onSubmit = async (values) => {
    const payload = {
      tracking_number: values.tracking_number,
      expedition_id: Number(values.expedition_type),
    };

    console.log(payload);

    try {
      await postShippingExpedition(cell.shipping_id, payload);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setTimeout(() => {
        onClose();
      }, 3000); // Same duration as the toast
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={2} spacingX={5} spacingY={5} mb="20px">
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Full Name</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={cell.full_name || ''}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={cell.phone_number || ''}
                    />
                  </FormControl>
                </GridItem>

                {/* Row 2 */}
                <GridItem colSpan={2}>
                  <FormControl mb={4}>
                    <FormLabel>Home Address</FormLabel>
                    <Textarea
                      readOnly
                      _readOnly={readOnlyColor}
                      value={cell.address || ''}
                      resize="none" // Prevents horizontal resizing, only allows vertical expansion
                      rows={1} // Initial row count
                      height="60px"
                    />
                  </FormControl>
                </GridItem>

                {/* Row 3 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Is Urgent</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={(cell.urgent ? 'Yes' : 'No') || ''}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Shipping Type</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={cell.shipping_type || ''}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              {/* Row 4 */}
              <Flex justify="start" alignItems="center" mb={4}>
                <Box>
                  <FormLabel fontSize="lg" fontWeight="bold" mb="0">
                    Product to ship
                  </FormLabel>
                </Box>
              </Flex>

              {/* Display Selected Products */}
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacingX={5}
                spacingY={5}
              >
                {cell.products.length != 0 && (
                  <>
                    <GridItem>
                      <FormLabel>Product Name</FormLabel>
                    </GridItem>
                    <GridItem>
                      <FormLabel>SKU</FormLabel>
                    </GridItem>
                    <GridItem>
                      <FormLabel>Quantity</FormLabel>
                    </GridItem>
                  </>
                )}
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacingX={5}
                spacingY={3}
                alignItems="center"
              >
                {cell.products.map((item, index) => (
                  <>
                    <GridItem>
                      <FormControl>
                        <Input
                          readOnly
                          _readOnly={readOnlyColor}
                          value={item.product.name}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Input
                          readOnly
                          _readOnly={readOnlyColor}
                          value={item.product.sku}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Input
                          readOnly
                          _readOnly={readOnlyColor}
                          value={item.quantity}
                        />
                      </FormControl>
                    </GridItem>
                  </>
                ))}
              </SimpleGrid>

              {/* Row 5 */}
              <Flex justify="start" alignItems="center" mt={8} mb={4}>
                <Box>
                  <FormLabel fontSize="lg" fontWeight="bold" mb="0">
                    Shipping detail
                  </FormLabel>
                </Box>
              </Flex>

              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacingX={5}
                spacingY={3}
                alignItems="center"
              >
                <GridItem>
                  <FormControl isRequired={true}>
                    <CustomInputForm
                      values={values}
                      label={'Tracking Number'}
                      id={'tracking_number'}
                      isUppercase={true}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired={true}>
                    <FormLabel>Expedition Type</FormLabel>
                    <Select
                      placeholder="Select expedition"
                      name="expedition_type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.expedition_type}
                    >
                      {expeditions.map((val, index) => (
                        <option value={val.id}>{val.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Box textAlign="right" mt={8}>
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
