import React from 'react';
import {
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';

function DetailCard({ title, components }) {
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <>
      <Menu>
        <MenuButton p="0px">
          <Text color="purple.500" cursor="pointer" textDecoration="underline">
            show
          </Text>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              {title}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {components != '' ? (
              components.map((item, key) => {
                // remove underscore & replace with space
                const formattedItem = item.includes('_')
                  ? item.replace(/_/g, ' ')
                  : item;

                return (
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    borderRadius="8px"
                    px="14px"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                  >
                    <Text
                      fontSize="sm"
                      whiteSpace="pre-wrap"
                      textTransform="capitalize"
                      color={textColor}
                    >
                      {formattedItem}
                    </Text>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
                borderBottom="1px solid"
                borderColor={borderColor}
              >
                <Text
                  fontSize="sm"
                  color="red"
                  whiteSpace="pre-wrap"
                  textTransform="capitalize"
                >
                  No Data
                </Text>
              </MenuItem>
            )}
          </Flex>
        </MenuList>
      </Menu>
    </>
  );
}

export default DetailCard;
