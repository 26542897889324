// @ts-nocheck
/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import React, { useMemo, useState } from 'react';
import FormComponent from './FormComponent';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DetailCardNegotiate from 'components/card/DetailCardNegotiate';

export default function DealingTable(props) {
  const { columnsData, tableData } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: formIsOpen,
    onOpen: formOnOpen,
    onClose: formOnClose,
  } = useDisclosure();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('navy.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  function getFirstFiveWords(str) {
    const string = str.trim().split(/\s+/);

    if (string.length > 5) {
      const words = str.split(' ');
      const firstFiveWords = words.slice(0, 5);
      return { word: firstFiveWords.join(' '), overlay: true };
    } else {
      return { word: str, overlay: false };
    }
  }

  function handleRowEdit(row) {
    setSelectedRow(row.original);
    formOnOpen();
  }

  function handleRowDelete(row) {
    console.log('row', row.id);
  }

  return (
    <>
      <Card direction="column" w="100%" px="0px" overflowX="scroll">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          __css={{ tableLayout: 'auto', width: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'APPROVAL DATE') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '140px', md: '140px', lg: '140px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'USERNAME') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'PLATFORM') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    // } else if (cell.column.Header === 'RATE CARD') {
                    //   let components = [];

                    //   cell.value.map((item, key) => {
                    //     const rate_item = 
                    //     `Harga asli    : Rp${
                    //       item.price
                    //     } \nHarga nego : Rp${
                    //       item.negotiated_rate_card?.price || ' -'
                    //     } \nSlot          : ${
                    //       item.negotiated_rate_card?.slot || ' -'
                    //     }`;
                    //     components.push({
                    //       subtitle: item.type,
                    //       subitems: rate_item,
                    //     });
                    //   });

                    //   data = (
                    //     <DetailCardNegotiate
                    //       title="Rate Card"
                    //       components={components}
                    //     />
                    //   );
                    // } else if (cell.column.Header === 'ADD COST') {
                    //   let components = [];

                    //   cell.value.map((item, key) => {
                    //     const rate_item = `Harga asli    : Rp${
                    //       item.price
                    //     } \nHarga nego : Rp${
                    //       item.negotiated_rate_card?.price || ' -'
                    //     }`;
                    //     components.push({
                    //       subtitle: item.type,
                    //       subitems: rate_item,
                    //     });
                    //   });

                    //   data = (
                    //     <DetailCardNegotiate
                    //       title="Rate Card"
                    //       components={components}
                    //     />
                    //   );
                    } else if (cell.column.Header === 'KOL CATEGORY') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'NOTE') {
                      const words = getFirstFiveWords(cell.value);
                      data = (
                        <>
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            <span style={{ color: 'black' }}>{words.word}</span>
                            <Popover>
                              <PopoverTrigger>
                                {words.overlay ? (
                                  <button
                                    onClick={onOpen}
                                    style={{ color: 'blue' }}
                                  >
                                    <Text
                                      color={'purple.500'}
                                      fontSize="sm"
                                      fontWeight="500"
                                    >
                                      &nbsp;readmore
                                    </Text>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody color="black">
                                  {cell.value}
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Text>
                        </>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          minW={{ sm: '150px', md: '300px', lg: '300px' }}
                        >
                          {data}
                        </Td>
                      );
                    } else if (cell.column.Header === 'DEALING TYPE') {
                      data = (
                        <Text
                          color={textColor}
                          fontSize="sm"
                          fontWeight="500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'ACTION') {
                      data = (
                        <Text
                          color="purple.500"
                          onClick={() => handleRowEdit(row)}
                          cursor="pointer"
                          textDecoration="underline"
                        >
                          update
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '150px', lg: '150px' }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Modal isOpen={formIsOpen} onClose={formOnClose} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth={{ base: '800px' }}>
            <ModalHeader>KOL Price Negotiation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormComponent cell={selectedRow} onClose={formOnClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Card>
    </>
  );
}
