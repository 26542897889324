// Chakra imports
import {
  Box,
  Flex,
  Spacer,
  Button,
  Text,
} from '@chakra-ui/react';
import { columnsDataDevelopment } from './columnsData';
// @ts-ignore
import React, { useEffect } from 'react';
import Pagination from 'components/footer/Pagination';
import Filter from 'components/menu/FilterMenu';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import NegotiateTable from './NegotiateTable';
import useDealing from 'hooks/useDealing';
import Loader from 'components/loader/Loader';
import { useLocation } from 'react-router-dom';

export default function Negotiate() {
  const { error, isLoading, dealing, totalDealing, getDealing } = useDealing();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract the 'page' and 'limit' parameters
  const page = queryParams.get('page') || 1; // Default to 1 if not provided
  const limit = queryParams.get('limit') || 10; // Default to 10 if not provided

  useEffect(() => {
    getDealing(page, limit);
  }, [page]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <Loader opacity={0.2}></Loader>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="34px">
            Negotiate
          </Text>
          <Box pt={{ base: '130px', md: '30px', xl: '30px' }}>
            <Flex px="25px" mb="20px" align="center">
              <Flex align="center">
                <SearchBar me="10px" borderRadius="30px" />
                <Filter />
              </Flex>
              <Spacer />
              <Button
                onClick={handleRefresh}
                bgColor="purple.300"
                color="white"
              >
                Refresh
              </Button>
            </Flex>
            <NegotiateTable
              columnsData={columnsDataDevelopment}
              tableData={dealing}
            />
            <Spacer mt={4} />
            <Pagination totalData={totalDealing} rowsPerPage={limit} />
          </Box>
        </>
      )}
    </>
  );
}
